/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActiveIntegrationJobType {
  GROUP_SYNC = "GROUP_SYNC",
  MEMBER_SYNC = "MEMBER_SYNC",
}

export enum AdminEventsInput_Filter_HostedIn {
  ASSOCIATION = "ASSOCIATION",
  GROUPS = "GROUPS",
  SPONSORS = "SPONSORS",
}

export enum AutoPayImportantDateType {
  MONTHLY = "MONTHLY",
  MONTHLY_RENEWAL = "MONTHLY_RENEWAL",
  RENEWAL = "RENEWAL",
}

export enum CsvSubdocumentImportType {
  ADD = "ADD",
  REMOVE = "REMOVE",
  REPLACE = "REPLACE",
}

export enum DateWindowType {
  AnyOverlap = "AnyOverlap",
  EndsWithin = "EndsWithin",
  ExcludeOverlap = "ExcludeOverlap",
  ExcludeWithin = "ExcludeWithin",
  StartsWithin = "StartsWithin",
  StrictlyWithin = "StrictlyWithin",
}

export enum DraftJS_ContentState_Block_InlineStyleRange_Styles {
  BOLD = "BOLD",
  CODE = "CODE",
  ITALIC = "ITALIC",
  STRIKETHROUGH = "STRIKETHROUGH",
  UNDERLINE = "UNDERLINE",
}

export enum EAccountCreationPermission {
  ANYONE = "ANYONE",
  INVITE_ONLY = "INVITE_ONLY",
}

export enum EAdType {
  DEFAULT_EVENT = "DEFAULT_EVENT",
  SPONSOR_PAGE = "SPONSOR_PAGE",
}

export enum EAnswerVote {
  DOWNVOTE = "DOWNVOTE",
  NONE = "NONE",
  UPVOTE = "UPVOTE",
}

export enum EAtMentionNotificationSetting {
  OFF = "OFF",
  REALTIME = "REALTIME",
}

/**
 * Compose a list of all editors, association admins can always edit and so are implied
 */
export enum EAttributeAllowedEditorKind {
  SELF = "SELF",
}

export enum EAttributeAllowedViewerKind {
  ASSOCIATION_MEMBER = "ASSOCIATION_MEMBER",
  GUEST = "GUEST",
  LOGGED_IN_USER = "LOGGED_IN_USER",
  SELF = "SELF",
}

/**
 * Indicates the valid `owners` of attributes that implement a particular `AttributeSchema`
 * 
 * `EAttributeOwnerKind.ORGANIZATION` includes both `Organization` and
 * `MembershipOrderDraftOrganization` AttributeOwners.
 */
export enum EAttributeOwnerKind {
  ORGANIZATION = "ORGANIZATION",
  USER = "USER",
}

export enum EAttributeSchemaValidationRule {
  EMAIL = "EMAIL",
  REQUIRED = "REQUIRED",
}

export enum EAttributeValidationRule {
  EMAIL = "EMAIL",
  REQUIRED = "REQUIRED",
  UNIQUE_ORGANIZATION_NAME = "UNIQUE_ORGANIZATION_NAME",
  UNIQUE_USER_EMAIL = "UNIQUE_USER_EMAIL",
}

export enum EAttribute_InputType {
  FREE_TEXT = "FREE_TEXT",
  MULTI_SELECT = "MULTI_SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
}

export enum EBlockGroupBlockAlignment {
  CENTER = "CENTER",
  LEFT = "LEFT",
}

export enum EBlockGroupImageAspectRatio {
  HORIZONTAL = "HORIZONTAL",
  SQUARE = "SQUARE",
  VERTICAL = "VERTICAL",
  VERY_HORIZONTAL = "VERY_HORIZONTAL",
  VERY_VERTICAL = "VERY_VERTICAL",
}

export enum EBlockGroupLinkBehavior {
  BUTTON = "BUTTON",
  CARD = "CARD",
}

export enum ECarouselLinkBehavior {
  BUTTON = "BUTTON",
  CARD = "CARD",
}

export enum ECommunityAccessConfig {
  LOGGED_IN_USERS = "LOGGED_IN_USERS",
  MEMBERS_ONLY = "MEMBERS_ONLY",
  PUBLIC = "PUBLIC",
}

export enum ECommunityVisibility {
  HIDDEN = "HIDDEN",
  VISIBLE = "VISIBLE",
}

export enum EDefaultNavigationItemIdentifier {
  COMMUNITY = "COMMUNITY",
  EVENTS = "EVENTS",
  MEMBERS = "MEMBERS",
  SPONSORS = "SPONSORS",
  SPONSOR_DASHBOARD = "SPONSOR_DASHBOARD",
}

export enum EDefaultPermissionConfig {
  ADMINS = "ADMINS",
  LOGGED_IN = "LOGGED_IN",
  MEMBERS = "MEMBERS",
  PUBLIC = "PUBLIC",
}

export enum EDigestPreference {
  DAILY = "DAILY",
  OFF = "OFF",
  REALTIME = "REALTIME",
  WEEKLY = "WEEKLY",
}

export enum EDraftJS_ContentState_Entity_Mutability {
  IMMUTABLE = "IMMUTABLE",
  MUTABLE = "MUTABLE",
  SEGMENTED = "SEGMENTED",
}

export enum EDraftJS_ContentState_Entity_Type {
  IMAGE = "IMAGE",
  LINK = "LINK",
  mention = "mention",
}

export enum EEmailMarketingIntegrationConstantContactStatus {
  CLIENT_ID_AND_SECRET_SET = "CLIENT_ID_AND_SECRET_SET",
  INITIALIZED = "INITIALIZED",
  UNINITIALIZED = "UNINITIALIZED",
}

/**
 * TODO(pranav): Name these `SIMPLE` or `CONFERENCE` (breaking change)
 */
export enum EEventType {
  ConferenceEvent = "ConferenceEvent",
  SimpleEvent = "SimpleEvent",
}

export enum EEventsFeedAccessConfig {
  HIDDEN = "HIDDEN",
  LOGGED_IN_USERS = "LOGGED_IN_USERS",
  PUBLIC = "PUBLIC",
}

export enum EGroupFindPermission {
  ADMINS = "ADMINS",
  GROUP_MEMBERS_ONLY = "GROUP_MEMBERS_ONLY",
  LOGGED_IN = "LOGGED_IN",
  MEMBER_STATUS_USERS = "MEMBER_STATUS_USERS",
  PUBLIC = "PUBLIC",
}

export enum EGroupSelfJoinPermission {
  ADMINS = "ADMINS",
  LOGGED_IN = "LOGGED_IN",
  MEMBER_STATUS_USERS = "MEMBER_STATUS_USERS",
  NOBODY = "NOBODY",
}

export enum EGroupViewContentPermission {
  SAME_AS_FIND = "SAME_AS_FIND",
  SAME_AS_SELF_JOIN_PLUS_GROUP_MEMBERS = "SAME_AS_SELF_JOIN_PLUS_GROUP_MEMBERS",
}

export enum EHeadingTextAlign {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum EHeroLinkBehavior {
  BUTTON = "BUTTON",
  CARD = "CARD",
}

export enum EImageGroupImageAlignment {
  CENTER = "CENTER",
  LEFT = "LEFT",
}

export enum ELinkSource {
  CREATED_PAGE = "CREATED_PAGE",
  LINK = "LINK",
}

export enum EMemberDirectoryAccessConfig {
  HIDDEN = "HIDDEN",
  LOGGED_IN_USERS = "LOGGED_IN_USERS",
  MEMBERS_ONLY = "MEMBERS_ONLY",
  PUBLIC = "PUBLIC",
}

/**
 * DEPRECATED
 */
export enum EMembershipSituation {
  GRACED = "GRACED",
  IN_GOOD_STANDING = "IN_GOOD_STANDING",
  LAPSED = "LAPSED",
  NOT_A_MEMBER = "NOT_A_MEMBER",
  UP_FOR_RENEWAL = "UP_FOR_RENEWAL",
}

export enum EMembershipType_Invoicing_Method {
  MANUAL = "MANUAL",
  NONE = "NONE",
}

export enum EMorgoth_RunMemberManagementCronJob {
  GRACE_PERIOD_EMAIL = "GRACE_PERIOD_EMAIL",
  MEMBERSHIP_EXPIRATION_CRON = "MEMBERSHIP_EXPIRATION_CRON",
  MEMBERSHIP_EXTRAORDINARY_PERIOD = "MEMBERSHIP_EXTRAORDINARY_PERIOD",
  MONTHLY_RENEWAL_CRON = "MONTHLY_RENEWAL_CRON",
  RENEWAL_AUTOPAY = "RENEWAL_AUTOPAY",
  RENEWAL_FREE = "RENEWAL_FREE",
  RENEWAL_PERIOD_EMAIL = "RENEWAL_PERIOD_EMAIL",
}

export enum EMorgoth_SyncAlgoliaIndexInput_IndexToSync {
  EVENTS = "EVENTS",
  FILES = "FILES",
  LIBRARY_FOLDERS = "LIBRARY_FOLDERS",
  ORGANIZATIONS = "ORGANIZATIONS",
  POSTS_AND_COMMENTS = "POSTS_AND_COMMENTS",
  RSVPS = "RSVPS",
  TAGS = "TAGS",
  USERS = "USERS",
}

export enum ENavigationItemRestriction {
  ANYONE = "ANYONE",
  LOGGED_IN = "LOGGED_IN",
  MEMBERS_ONLY = "MEMBERS_ONLY",
  NOBODY = "NOBODY",
}

export enum ENavigationItemStructure {
  DIRECT_LINK = "DIRECT_LINK",
  DROPDOWN = "DROPDOWN",
}

export enum EOnlinePlatformType {
  EXTERNAL = "EXTERNAL",
  TRADEWING = "TRADEWING",
}

export enum EPageContentBackgroundColor {
  TRANSPARENT = "TRANSPARENT",
  WHITE = "WHITE",
}

export enum EPageRestriction {
  ANYONE = "ANYONE",
  LOGGED_IN = "LOGGED_IN",
  MEMBERS_ONLY = "MEMBERS_ONLY",
}

export enum EPermissionGroupType {
  DYNAMIC = "DYNAMIC",
  INTEGRATION = "INTEGRATION",
  MANUAL = "MANUAL",
}

export enum EPrepImagesSource {
  AD = "AD",
  BLOG = "BLOG",
  BLOG_EMBEDDED_IMAGE = "BLOG_EMBEDDED_IMAGE",
  COMMENT = "COMMENT",
  CONFERENCE_BOOTH = "CONFERENCE_BOOTH",
  CONFERENCE_SESSION = "CONFERENCE_SESSION",
  DEFAULT = "DEFAULT",
  EVENT_BANNER_IMAGE = "EVENT_BANNER_IMAGE",
  GROUP = "GROUP",
  POST = "POST",
  PROFILE_PICTURE = "PROFILE_PICTURE",
  SPONSOR_BANNER_IMAGE = "SPONSOR_BANNER_IMAGE",
  SPONSOR_PROFILE_PICTURE = "SPONSOR_PROFILE_PICTURE",
  TENANT_LOGO = "TENANT_LOGO",
}

export enum EPrepVersionFilesSource {
  FEED_ITEM = "FEED_ITEM",
  LIBRARY = "LIBRARY",
}

export enum EReactionType {
  LIKE = "LIKE",
}

export enum ERegistrationType {
  OPEN = "OPEN",
  TICKET = "TICKET",
}

export enum ERsvpResponse {
  GOING = "GOING",
  MAYBE = "MAYBE",
  NOT_GOING = "NOT_GOING",
}

export enum ESponsorDirectoryAccessConfig {
  HIDDEN = "HIDDEN",
  LOGGED_IN_USERS = "LOGGED_IN_USERS",
  PUBLIC = "PUBLIC",
}

export enum ESponsorTierType {
  LITE = "LITE",
  PLUS = "PLUS",
  PREMIUM = "PREMIUM",
  PRO = "PRO",
}

export enum EStreamStatus {
  ACTIVE = "ACTIVE",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  IDLE = "IDLE",
  RECORDING = "RECORDING",
}

export enum ETenantPaymentAccountStatus {
  NOT_CONNECTED = "NOT_CONNECTED",
  UNSUPPORTED_CURRENCY = "UNSUPPORTED_CURRENCY",
  VALID = "VALID",
}

export enum ETicketCanPurchaseType {
  MAGIC_LINK = "MAGIC_LINK",
  PERMISSION_CONFIG = "PERMISSION_CONFIG",
}

export enum ETimeRange {
  ALL_TIME = "ALL_TIME",
  LAST_YEAR = "LAST_YEAR",
  SIX_MONTHS = "SIX_MONTHS",
  THIRTY_DAYS = "THIRTY_DAYS",
  THREE_MONTHS = "THREE_MONTHS",
}

export enum ETradewingPlatformStreamType {
  LIVE_STREAM = "LIVE_STREAM",
  PRE_RECORDED_VIDEO = "PRE_RECORDED_VIDEO",
}

export enum EUserRole {
  ADMIN = "ADMIN",
  MORGOTH = "MORGOTH",
  ORGANIZATION_ACCOUNT_OWNER = "ORGANIZATION_ACCOUNT_OWNER",
  ORGANIZATION_PAGE_ADMIN = "ORGANIZATION_PAGE_ADMIN",
  REGULAR_USER = "REGULAR_USER",
}

export enum EventEmailType {
  CONFIRMATION = "CONFIRMATION",
  PROMOTION = "PROMOTION",
  REMINDER = "REMINDER",
  SUMMARY = "SUMMARY",
}

export enum EventInteraction {
  CLICKED_EXTERNAL_EVENT_LINK = "CLICKED_EXTERNAL_EVENT_LINK",
}

export enum EventStatus {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export enum EventVenueType {
  HYBRID = "HYBRID",
  IN_PERSON = "IN_PERSON",
  ONLINE = "ONLINE",
}

export enum EventVisibilityType {
  ADMINS = "ADMINS",
  ANYONE = "ANYONE",
  GROUP_MEMBERS = "GROUP_MEMBERS",
  LOGGED_IN = "LOGGED_IN",
  MEMBERS = "MEMBERS",
  SPECIFIC_USERS = "SPECIFIC_USERS",
  TICKET_HOLDERS = "TICKET_HOLDERS",
}

export enum IntegrationJobType {
  DEFAULT_MEMBER_SYNC = "DEFAULT_MEMBER_SYNC",
  FULL_MEMBER_SYNC = "FULL_MEMBER_SYNC",
  GROUP_SYNC = "GROUP_SYNC",
}

export enum MatchCondition {
  ALL = "ALL",
  ANY = "ANY",
}

export enum MemberManagementMorgothActionJobName {
  SET_COMPUTED_FIELDS_ON_ALL_TERMS_FOR_ALL_MM_TENANTS_JOB = "SET_COMPUTED_FIELDS_ON_ALL_TERMS_FOR_ALL_MM_TENANTS_JOB",
}

export enum MembershipCTAType {
  ACH_VERIFICATION_NEEDED = "ACH_VERIFICATION_NEEDED",
  PAST_DUE = "PAST_DUE",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
  UNPAID_INVOICE = "UNPAID_INVOICE",
  UP_FOR_RENEWAL = "UP_FOR_RENEWAL",
}

export enum MembershipOrderVariant {
  FIRST = "FIRST",
  MONTHLY_DUES = "MONTHLY_DUES",
  RECURRING = "RECURRING",
}

export enum MembershipStatus {
  LAPSED = "LAPSED",
  MEMBER = "MEMBER",
  NOT_A_MEMBER = "NOT_A_MEMBER",
}

/**
 * Combination of enums `MembershipTermStatusAlert` and `MembershipTermPaymentAlert`
 */
export enum MembershipTermAlert {
  ACH_PROCESSING = "ACH_PROCESSING",
  ACH_VERIFICATION_NEEDED = "ACH_VERIFICATION_NEEDED",
  PAST_DUE = "PAST_DUE",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
  RECENTLY_LAPSED = "RECENTLY_LAPSED",
  UNPAID_INVOICE = "UNPAID_INVOICE",
  UP_FOR_RENEWAL = "UP_FOR_RENEWAL",
}

export enum MembershipTermAlertSearchFilter {
  ACH_PROCESSING = "ACH_PROCESSING",
  ACH_VERIFICATION_NEEDED = "ACH_VERIFICATION_NEEDED",
  NONE = "NONE",
  PAST_DUE = "PAST_DUE",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
  RECENTLY_LAPSED = "RECENTLY_LAPSED",
  UNPAID_INVOICE = "UNPAID_INVOICE",
  UP_FOR_RENEWAL = "UP_FOR_RENEWAL",
}

export enum MembershipTermLifecycleStatus {
  EXPIRED = "EXPIRED",
  GRACED = "GRACED",
  IMPENDING = "IMPENDING",
  ORDINARY = "ORDINARY",
  RENEWING = "RENEWING",
}

export enum MembershipTermPaymentAlert {
  ACH_PROCESSING = "ACH_PROCESSING",
  ACH_VERIFICATION_NEEDED = "ACH_VERIFICATION_NEEDED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  UNPAID_INVOICE = "UNPAID_INVOICE",
}

export enum MembershipTermPaymentStatus {
  MONTHLY_PAYMENTS_REMAINING = "MONTHLY_PAYMENTS_REMAINING",
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum MembershipTermRenewalSetting {
  AUTOMATIC_RENEWAL = "AUTOMATIC_RENEWAL",
  NOT_RENEWING = "NOT_RENEWING",
  RENEWAL_REMINDERS = "RENEWAL_REMINDERS",
}

export enum MembershipTermStatusAlert {
  PAST_DUE = "PAST_DUE",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
  RECENTLY_LAPSED = "RECENTLY_LAPSED",
  UP_FOR_RENEWAL = "UP_FOR_RENEWAL",
}

export enum OnboardingFlowState {
  ACCEPT_CODE_OF_CONDUCT = "ACCEPT_CODE_OF_CONDUCT",
  CONFIRM_DETAILS = "CONFIRM_DETAILS",
  DOWNLOAD_APP = "DOWNLOAD_APP",
  FOLLOW_SPONSORS = "FOLLOW_SPONSORS",
  JOIN_GROUP = "JOIN_GROUP",
  JOIN_TAGS = "JOIN_TAGS",
}

export enum OrderStatus {
  COMPLETE = "COMPLETE",
  INITIALIZED = "INITIALIZED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
}

export enum OrganizationCsvUploadOperator {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
}

export enum PaymentImportantDateType {
  INVOICE_DUE = "INVOICE_DUE",
  MEMBERSHIP_FEE = "MEMBERSHIP_FEE",
  RENEWAL_DUE = "RENEWAL_DUE",
  RENEWAL_PERIOD_START = "RENEWAL_PERIOD_START",
}

export enum PaymentIntent_SetupFutureUsage {
  NONE = "NONE",
  OFF_SESSION = "OFF_SESSION",
  ON_SESSION = "ON_SESSION",
}

export enum PaymentInterval {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  NO_PAYMENT_REQUIRED = "NO_PAYMENT_REQUIRED",
}

export enum SimpleImportantDateType {
  GRACE_PERIOD_END = "GRACE_PERIOD_END",
  TERM_END = "TERM_END",
  TERM_START = "TERM_START",
}

/**
 * column ordering options
 */
export enum SortOptions {
  asc = "asc",
  desc = "desc",
}

export enum UserConsentType {
  DEMO_REQUESTED = "DEMO_REQUESTED",
  FOLLOWED_SPONSOR = "FOLLOWED_SPONSOR",
}

export enum UserCsvUploadOperator {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
}

export enum UserSearchInput_Filter_EAccountStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  INVITED = "INVITED",
  NOT_INVITED = "NOT_INVITED",
}

export interface AcceptCodeOfConductInput {
  isPartOfOnboardingFlow: boolean;
}

export interface ActivateMembershipTypeInput {
  id: string;
}

export interface ActivateUserInput {
  userId: string;
}

export interface ActivateUserWithPasswordInput {
  password: string;
}

export interface ActiveEventAttendeesInput {
  sessionId: string;
}

export interface ActivityByAuthorInputV2 {
  authorId: string;
  includePostsCommentedUponByAuthorId?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderDirection?: SortOptions | null;
}

export interface AddConferenceBoothResourcesInput {
  conferenceBoothId: string;
  resourceIds: string[];
}

export interface AddConferenceSessionResourcesInput {
  conferenceSessionId: string;
  resourceIds: string[];
}

export interface AddFilesToGroupLibraryInput {
  groupId: string;
  libraryFolderId: string;
  versionFileIds: string[];
}

export interface AddSimpleEventResourcesInput {
  resourceIds: string[];
  simpleEventId: string;
}

export interface AddUsersToOrganizationRosterInput {
  existingUsers?: OrganizationRosterExistingUser[] | null;
  newUsers?: OrganizationRosterNewUser[] | null;
  organizationId: string;
}

export interface AdminEventsInput {
  filter?: AdminEventsInput_Filter | null;
  limit?: number | null;
  offset?: number | null;
  sortOptions?: AdminEventsInput_SortOptions | null;
}

export interface AdminEventsInput_Filter {
  dateWindow?: DateWindowSearch | null;
  eventStatus?: EventStatus[] | null;
  eventType?: EEventType | null;
  hostedIn?: AdminEventsInput_Filter_HostedIn[] | null;
  registrationType?: ERegistrationType[] | null;
  searchText?: string | null;
  venueType?: EventVenueType[] | null;
}

export interface AdminEventsInput_SortOptions {
  name?: SortOptions | null;
  startDate?: SortOptions | null;
}

export interface AdoptableGroupsData {
  filter?: AdoptableGroupsFilter | null;
}

/**
 * adoptableGroups
 */
export interface AdoptableGroupsFilter {
  searchTerm?: string | null;
}

export interface AdoptableGroupsInput {
  data: AdoptableGroupsData;
}

export interface AggregateInvitationStatusForUsersV3Input {
  filter?: AggregateInvitationStatusForUsersV3Input_Filter | null;
  userIds?: string[] | null;
}

export interface AggregateInvitationStatusForUsersV3Input_Filter {
  accountStatusV2?: UserSearchInput_Filter_EAccountStatus[] | null;
  excludeUserIds?: string[] | null;
  membershipStatusV3?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  role?: EUserRole[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface AllTopLevelGroupsData {
  filter: AllTopLevelGroupsFilter;
  limit?: number | null;
  offset?: number | null;
}

/**
 * adminTopLevelGroups
 */
export interface AllTopLevelGroupsFilter {
  communityVisibility?: ECommunityVisibility | null;
  searchTerm?: string | null;
}

export interface AllTopLevelGroupsInput {
  data: AllTopLevelGroupsData;
}

export interface AnnouncementFeedInput_V2 {
  cursor?: string | null;
  groupId?: string | null;
  limit?: number | null;
}

export interface AttendanceReportInput {
  limit?: number | null;
  offset?: number | null;
}

export interface AttributeSchemaInput {
  id: string;
}

export interface AttributeSchemasFilterInput {
  isEnabled?: boolean | null;
  ownerKind?: EAttributeOwnerKind | null;
  query?: string | null;
}

export interface AttributeSchemasInput {
  filter?: AttributeSchemasFilterInput | null;
  limit?: number | null;
  offset?: number | null;
}

export interface AvailableAttributeSchemasForMembershipTypeFilterInput {
  query?: string | null;
}

export interface AvailableAttributeSchemasForMembershipTypeInput {
  filter?: AvailableAttributeSchemasForMembershipTypeFilterInput | null;
  limit?: number | null;
  offset?: number | null;
  ownerKind: EAttributeOwnerKind;
}

export interface AvailableAttributeSchemasForTicketInput {
  eventId: string;
  query?: string | null;
}

export interface BlockGroupInput {
  blocks: BlockInput[];
  name?: string | null;
  options?: BlockGroupOptionsInput | null;
  variant?: number | null;
}

export interface BlockGroupOptionsInput {
  backgroundColor?: EPageContentBackgroundColor | null;
  blockAlignment?: EBlockGroupBlockAlignment | null;
  blockImageAspectRatio?: EBlockGroupImageAspectRatio | null;
  blockLinkBehavior?: EBlockGroupLinkBehavior | null;
  columns?: number | null;
  isBlockBodyTextEnabled?: boolean | null;
  isBlockImageEnabled?: boolean | null;
  isBlockSubtitleEnabled?: boolean | null;
  isBlockTitleEnabled?: boolean | null;
}

export interface BlockInput {
  body?: string | null;
  imageId?: string | null;
  linkHref?: string | null;
  linkPageId?: string | null;
  linkSource?: ELinkSource | null;
  linkText?: string | null;
  name?: string | null;
  subtitle?: string | null;
  title?: string | null;
}

export interface BooleanFacetInput {
  isTrue: boolean;
}

export interface Boolean_Selector {
  _eq?: boolean | null;
}

export interface BulkDeletePermissionGroupsInput {
  permissionGroupIds: string[];
}

export interface CancelQueuedMembershipTermInput {
  membershipTermId: string;
}

export interface CancelRegistrationInput {
  registrationId: string;
}

export interface CarouselInput {
  carouselItems: CarouselItemInput[];
  name?: string | null;
  options?: CarouselOptionsInput | null;
  variant?: number | null;
}

export interface CarouselItemInput {
  body?: string | null;
  imageId?: string | null;
  linkHref?: string | null;
  linkPageId?: string | null;
  linkSource?: ELinkSource | null;
  linkText?: string | null;
  name?: string | null;
  subtitle?: string | null;
  title?: string | null;
}

export interface CarouselOptionsInput {
  carouselAutoChangeSpeed?: number | null;
  carouselItemLinkBehavior?: ECarouselLinkBehavior | null;
  isCarouselAutoChangeEnabled?: boolean | null;
  isCarouselItemBodyTextEnabled?: boolean | null;
  isCarouselItemSubtitleEnabled?: boolean | null;
  isCarouselItemTitleEnabled?: boolean | null;
}

export interface ChangeUserRoleInput {
  organizationId?: string | null;
  role: EUserRole;
  userId: string;
}

export interface ChatDetail_Input {
  streamChannelId: string;
}

export interface CheckEmailForCreatingUserToAddToCompanyRosterInput {
  email: string;
}

export interface CommentFilterInput {
  _and?: (CommentFilterInput | null)[] | null;
  _id?: String_Selector | null;
  _inDateRange?: CommentInDateRangeFilterInput | null;
  _not?: CommentFilterInput | null;
  _onBlogs?: CommentOnBlogsFilterInput | null;
  _or?: (CommentFilterInput | null)[] | null;
  _withSort?: CommentWithSortFilterInput | null;
  _withTextSearch?: CommentWithTextSearchFilterInput | null;
  authorId?: String_Selector | null;
  bodyPlainText?: String_Selector | null;
  commentId?: String_Selector | null;
  dateCreated?: Date_Selector | null;
  datePublished?: Date_Selector | null;
  moderationItemId?: String_Selector | null;
  postId?: String_Selector | null;
  reactionCount?: Int_Selector | null;
  score?: Float_Selector | null;
  streamId?: String_Selector | null;
  streamNotificationActivityId?: String_Selector | null;
  tenantId?: String_Selector | null;
}

export interface CommentInDateRangeFilterInput {
  dateEnd?: string | null;
  dateStart?: string | null;
}

export interface CommentOnBlogsFilterInput {
  isBlog: boolean;
}

export interface CommentWithSortFilterInput {
  sortObj: JSON;
}

export interface CommentWithTextSearchFilterInput {
  searchText: string;
}

export interface CommentsByIdsInput {
  commentIds: string[];
}

export interface CommunityListeningPostFeedInputV2 {
  cursor?: string | null;
}

export interface CompleteFreeMembershipOrderInput {
  membershipOrderId: string;
}

export interface ConferenceBoothInput {
  conferenceBoothId: string;
}

export interface ConferenceBoothResourcesInput {
  limit: number;
  offset: number;
}

export interface ConferenceBoothsByOrganizationInput {
  filter?: ConferenceBoothsByOrganization_Filter | null;
  limit?: number | null;
  offset?: number | null;
  organizationId: string;
}

export interface ConferenceBoothsByOrganization_Filter {
  textSearch?: string | null;
}

export interface ConferenceEvent_SessionsInput {
  filter?: ConferenceEvent_SessionsInput_Filter | null;
  limit?: number | null;
  offset?: number | null;
}

export interface ConferenceEvent_SessionsInput_Filter {
  dateWindow?: DateWindowSearch | null;
  liveNowOnly?: boolean | null;
  searchQuery?: string | null;
}

export interface ConferenceSessionInput {
  conferenceSessionId: string;
}

export interface ConferenceSessionResourcesInput {
  limit: number;
  offset: number;
}

export interface ConfirmUserDetailsInput {
  data: ConfirmUserDetailsInput_Data;
}

export interface ConfirmUserDetailsInput_Data {
  company: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
}

export interface CreateAdDataInput {
  adImageIdV2: string;
  body: string;
  callToAction: string;
  organizationId: string;
  published?: boolean | null;
  registerInterestTitle?: string | null;
  title: string;
  type: EAdType;
  url: string;
}

export interface CreateAdInput {
  data: CreateAdDataInput;
}

export interface CreateAnnouncementAndUpdatePostInput_V2 {
  postId: string;
}

export interface CreateAnswerDataInput_V2 {
  body: RawDraftContentState;
  embeddedImageIds?: string[] | null;
  questionPostId: string;
  sponsorData?: CreatePostDataInput_SponsorData | null;
  tagIds?: string[] | null;
  versionFileIds?: string[] | null;
}

export interface CreateAnswerInput_V2 {
  data: CreateAnswerDataInput_V2;
  isAnnouncement: boolean;
}

export interface CreateAttributeSchemaInput {
  allowedEditors: EAttributeAllowedEditorKind[];
  defaultAllowedViewers: EAttributeAllowedViewerKind[];
  inputType: EAttribute_InputType;
  isOnProfile: boolean;
  isSearchable: boolean;
  name: string;
  options?: CreateAttributeSchemaInput_Option[] | null;
  ownerKind: EAttributeOwnerKind;
}

export interface CreateAttributeSchemaInput_Option {
  label: string;
}

export interface CreateBlogDataInput_V2 {
  blogBannerImageId?: string | null;
  content: RawDraftContentState;
  groupId?: string | null;
  organizationId?: string | null;
  published: boolean;
  scheduledPublishDate?: string | null;
  sponsorData?: CreatePostDataInput_SponsorData | null;
  tagIds?: string[] | null;
  title: string;
}

export interface CreateBlogInput_V2 {
  data: CreateBlogDataInput_V2;
  isAnnouncement?: boolean | null;
}

export interface CreateChatWithOrganization {
  message?: string | null;
  organizationId: string;
  userId: string;
}

export interface CreateCommentDataInput_V2 {
  body: RawDraftContentState;
  commentId?: string | null;
  embeddedImageIds?: string[] | null;
  postId: string;
  versionFileIds?: string[] | null;
}

export interface CreateCommentInput_V2 {
  data: CreateCommentDataInput_V2;
}

export interface CreateCommentReactionInput {
  commentId: string;
  reactionType: EReactionType;
}

export interface CreateCompanyAccountOwnerInput {
  accountEmail: string;
  firstName: string;
  lastName: string;
  shouldSendInvitationEmail: boolean;
}

export interface CreateCompanyInput {
  companyAccountOwnerUserId?: string | null;
  companyName: string;
  isSponsor: boolean;
  newCompanyAccountOwner?: CreateCompanyAccountOwnerInput | null;
}

export interface CreateConferenceBoothInput {
  alternativeVideoFileId?: string | null;
  canonicalVideoFileId?: string | null;
  conferenceEventId: string;
  description?: string | null;
  hostOrganizationId: string;
  imageId?: string | null;
  linkToRecording?: LinkToRecordingInput | null;
  moderators?: NewOrExistingSpeakerInput[] | null;
  specialOffer?: CreateConferenceBoothInput_SpecialOffer | null;
  venue: CreateEventVenueInput;
}

export interface CreateConferenceBoothInput_SpecialOffer {
  adImageId: string;
  body: string;
  callToAction: string;
  registerInterestTitle: string;
  title: string;
  url: string;
}

export interface CreateConferenceEventInput {
  bannerImageId?: string | null;
  description?: RawDraftContentState | null;
  endDate: string;
  externalRegistrationUrl?: string | null;
  inPersonLocation?: EventVenueInput_InPersonLocation | null;
  isExternalRegistrationUrlActive?: boolean | null;
  name: string;
  shortDescription?: string | null;
  startDate: string;
  timeZone: string;
  venueType: EventVenueType;
  visibility: EventVisibilityInput;
}

export interface CreateConferenceSessionInput {
  eventId: string;
  session: CreateConferenceSessionInput_Session;
}

export interface CreateConferenceSessionInput_Session {
  alternativeVideoFileId?: string | null;
  canonicalVideoFileId?: string | null;
  description?: string | null;
  endDate: string;
  imageId?: string | null;
  linkToRecording?: LinkToRecordingInput | null;
  speakers?: NewOrExistingSpeakerInput[] | null;
  sponsorData?: KeyAndAdditionalSponsorInputs | null;
  startDate: string;
  title: string;
  venue: CreateEventVenueInput;
  visibility: EventVisibilityInput;
}

export interface CreateCustomerInput {
  associationCode: string;
  subdomain: string;
}

export interface CreateDynamicPermissionGroupInput {
  description?: RawDraftContentState | null;
  name: string;
  rule: UserRuleInput;
}

export interface CreateEventTicketInput {
  data: CreateEventTicketInput_Data;
  eventId: string;
}

export interface CreateEventTicketInput_Data {
  attributeReviewConfigurations?: TicketAttributeReviewConfigurationInput[] | null;
  canPurchase: CreateEventTicketInput_Data_CanPurchase;
  description?: string | null;
  name: string;
  price: Money;
}

export interface CreateEventTicketInput_Data_CanPurchase {
  magicTicketLink?: boolean | null;
  permissionConfig?: PermissionConfigInput | null;
}

/**
 * Either exactly 1, or none of `inPersonLocation` and `online` must be specified
 */
export interface CreateEventVenueInput {
  inPersonLocation?: EventVenueInput_InPersonLocation | null;
  online?: CreateEventVenueInput_OnlinePlatform | null;
  type: EventVenueType;
}

/**
 * Either exactly 1, or none of `tradewingPlatform` and `externalPlatform` must be specified
 */
export interface CreateEventVenueInput_OnlinePlatform {
  externalPlatform?: EventVenueInput_OnlinePlatform_ExternalPlatform | null;
  tradewingPlatform?: CreateEventVenueInput_OnlinePlatform_TradewingPlatform | null;
  type: EOnlinePlatformType;
}

/**
 * Either exactly 1, or none of `preRecordedVideo` and `liveVideoStream` must be specified
 */
export interface CreateEventVenueInput_OnlinePlatform_TradewingPlatform {
  liveVideoStream?: EventVenueInput_OnlinePlatform_TradewingPlatform_LiveVideoStream | null;
  preRecordedVideo?: EventVenueInput_OnlinePlatform_TradewingPlatform_PreRecordedVideo | null;
  type: ETradewingPlatformStreamType;
}

export interface CreateGroupDataInput {
  dateCreated?: string | null;
  description?: string | null;
  externalId?: string | null;
  groupBannerImageId?: string | null;
  groupProfileImageId?: string | null;
  libraryId?: string | null;
  name: string;
  parentGroupId?: string | null;
  priority?: number | null;
  settings: CreateGroupSettingsDataInput;
}

export interface CreateGroupInput {
  data: CreateGroupDataInput;
}

export interface CreateGroupLibraryFolderInput {
  groupId: string;
  name: string;
  parentLibraryFolderId: string;
}

export interface CreateGroupSettingsDataInput {
  communityVisibility?: ECommunityVisibility | null;
  defaultDigestPref: EDigestPreference;
  findPermission: EGroupFindPermission;
  selfJoinPermission?: EGroupSelfJoinPermission | null;
  viewContentPermission?: EGroupViewContentPermission | null;
}

export interface CreateInvoiceForMembershipOrderInput {
  membershipOrderId: string;
}

export interface CreateMembershipTypeInput {
  acceptedPaymentMethods: CreateMembershipTypeInput_AcceptedPaymentMethods;
  attributeReviewConfigurations?: MembershipTypeAttributeReviewConfigurationInput[] | null;
  description?: RawDraftContentState | null;
  hasCustomWelcomeEmail?: boolean | null;
  invoicing?: CreateMembershipTypeInput_Invoicing | null;
  isCorporate: boolean;
  isInvoicingEnabled: boolean;
  membershipRenewalSettings: CreateMembershipTypeInput_MembershipRenewalSettings;
  monthlyPrice?: Money | null;
  name: string;
  numberOfSeats?: number | null;
  price: Money;
  publiclyVisible: boolean;
  welcomeEmail?: CreateMembershipTypeInput_WelcomeEmail | null;
}

export interface CreateMembershipTypeInput_AcceptedPaymentMethods {
  achTransfer?: boolean | null;
  creditCard?: boolean | null;
}

export interface CreateMembershipTypeInput_Invoicing {
  method: EMembershipType_Invoicing_Method;
}

export interface CreateMembershipTypeInput_MembershipRenewalSettings {
  gracePeriodInDays: number;
  renewalPeriodInDays: number;
}

export interface CreateMembershipTypeInput_WelcomeEmail {
  body: string;
  subject: string;
}

export interface CreateModerationItemDataInputV2 {
  commentId?: string | null;
  postId?: string | null;
  reason: string;
  resolutionState: string;
}

export interface CreateModerationItemInputV2 {
  data: CreateModerationItemDataInputV2;
}

export interface CreateMyNewMembershipOrderInput {
  companyName?: string | null;
  membershipTypeId: string;
  paymentInterval?: PaymentInterval | null;
}

export interface CreateNewMembershipOrderInput {
  endDate?: string | null;
  isConsideredPaid?: boolean | null;
  membershipOrganizationId?: string | null;
  membershipTypeId: string;
  membershipUserId?: string | null;
  paymentInterval?: PaymentInterval | null;
  startDate?: string | null;
}

export interface CreateOidcClientData {
  name: string;
}

export interface CreateOidcClientInput {
  data: CreateOidcClientData;
}

export interface CreateOrganizationLeadDataInput {
  company?: string | null;
  conferenceBoothId?: string | null;
  createdByUserId: string;
  dateCreated?: string | null;
  displayName: string;
  email: string;
  jobTitle?: string | null;
  organizationId: string;
  origin?: CreateOrganizationLeadInput_Origin | null;
  primaryPhone?: string | null;
}

export interface CreateOrganizationLeadInput {
  data: CreateOrganizationLeadDataInput;
}

export interface CreateOrganizationLeadInput_Origin {
  conferenceBoothId?: string | null;
  featuredSponsorCard?: boolean | null;
  hostedEventId?: string | null;
  sponsoredConferenceSessionId?: string | null;
  sponsoredEventId?: string | null;
  sponsorPage?: boolean | null;
}

export interface CreateOrganizationTierLogInput {
  organizationId: string;
  tierStartDate: string;
  tierType: ESponsorTierType;
}

export interface CreateOrganizationUpgradeRequestInput {
  organizationId: string;
  source: string;
}

export interface CreateOrganizationV2Input {
  aboutUs?: RawDraftContentState | null;
  city?: string | null;
  contactEmail?: string | null;
  country?: string | null;
  createWithAdmins?: CreateOrganizationV2Input_CreateWithAdmins | null;
  isMember?: boolean | null;
  isSponsor: boolean;
  name: string;
  phoneNumber?: string | null;
  requestDemoTitle?: string | null;
  sponsorBannerImageId?: string | null;
  sponsorProfilePictureId?: string | null;
  state?: string | null;
  streetAddress?: string | null;
  website?: string | null;
  zipCode?: string | null;
}

export interface CreateOrganizationV2Input_CreateWithAdmins {
  adminUserIds: string[];
  newAdminUserData: CreateOrganizationV2Input_CreateWithAdmins_NewAdminUserData[];
}

export interface CreateOrganizationV2Input_CreateWithAdmins_NewAdminUserData {
  email: string;
  firstName: string;
  lastName: string;
  sendEmailInvitation: boolean;
}

export interface CreatePageInput {
  content?: PageContentInput[] | null;
  isMarketingHomepage?: boolean | null;
  name: string;
  restriction?: EPageRestriction | null;
  slug: string;
  titleTag?: string | null;
}

export interface CreatePermissionGroupInput {
  description?: RawDraftContentState | null;
  name: string;
  type: EPermissionGroupType;
}

export interface CreatePollDataInput_V2 {
  body: RawDraftContentState;
  embeddedImageIds?: string[] | null;
  eventId?: string | null;
  groupId?: string | null;
  options: CreatePollOptionDataInput[];
  organizationId?: string | null;
  scheduledPublishDate?: string | null;
  sponsorData?: CreatePostDataInput_SponsorData | null;
  tagIds?: string[] | null;
  title: string;
  type: string;
  versionFileIds?: string[] | null;
}

export interface CreatePollInput_V2 {
  data: CreatePollDataInput_V2;
  isAnnouncement: boolean;
}

export interface CreatePollOptionDataInput {
  description: string;
  order?: number | null;
  pollId?: string | null;
  responses: (JSON | null)[];
  tenantId?: string | null;
}

export interface CreatePostDataInput_SponsorData {
  audience: CreatePostDataInput_SponsorData_Audience;
  isSponsoredPost: boolean;
}

export interface CreatePostDataInput_SponsorData_Audience {
  groupId?: string | null;
}

export interface CreatePostReactionInput {
  postId: string;
  reactionType: EReactionType;
}

export interface CreateQuestionDataInput_V2 {
  body: RawDraftContentState;
  embeddedImageIds?: string[] | null;
  eventId?: string | null;
  groupId?: string | null;
  organizationId?: string | null;
  scheduledPublishDate?: string | null;
  sponsorData?: CreatePostDataInput_SponsorData | null;
  tagIds?: string[] | null;
  title: string;
  versionFileIds?: string[] | null;
}

export interface CreateQuestionInput_V2 {
  data: CreateQuestionDataInput_V2;
  isAnnouncement: boolean;
}

export interface CreateRegistrationsForEventInput {
  eventId: string;
  registrationInputs: RegistrationInput[];
}

export interface CreateRsvpForEventInput {
  attendeeId: string;
  eventId: string;
  response: ERsvpResponse;
}

export interface CreateSimpleEventInput {
  alternativeVideoFileId?: string | null;
  bannerImageId?: string | null;
  canonicalVideoFileId?: string | null;
  description?: RawDraftContentState | null;
  endDate: string;
  externalRegistrationUrl?: string | null;
  hostOrganizationId?: string | null;
  isExternalRegistrationUrlActive?: boolean | null;
  linkToRecording?: LinkToRecordingInput | null;
  name: string;
  registrationType: ERegistrationType;
  speakersV2?: NewOrExistingSpeakerInput[] | null;
  sponsorData?: KeyAndAdditionalSponsorInputs | null;
  startDate: string;
  timeZone: string;
  venue: CreateEventVenueInput;
  visibility: EventVisibilityInput;
}

export interface CreateSimplePostInput {
  isAnnouncement?: boolean | null;
  post: CreateSimplePostInput_Data;
}

export interface CreateSimplePostInput_Data {
  body?: RawDraftContentState | null;
  embeddedImageIds?: string[] | null;
  eventId?: string | null;
  groupId?: string | null;
  organizationId?: string | null;
  scheduledPublishDate?: string | null;
  sponsorData?: CreatePostDataInput_SponsorData | null;
  tagIds?: string[] | null;
  title?: string | null;
  versionFileIds?: string[] | null;
}

export interface CreateTagInput {
  name: string;
}

export interface CreateUserGroupLinkDataInput {
  dateCreated?: string | null;
  digestPreference?: string | null;
  externalId?: string | null;
  groupId: string;
  lastAnnouncementViewDate?: string | null;
  lastIntegrationJobId?: string | null;
  tenantId?: string | null;
  userGroupRole?: string | null;
  userId: string;
}

export interface CreateUserGroupLinkInput {
  data: CreateUserGroupLinkDataInput;
}

export interface CreateUserNoteInput {
  body: string;
  userId: string;
}

export interface CreateUserOrganizationLinkDataInput {
  contactConsented?: boolean | null;
  dateCreated?: string | null;
  digestPreference?: string | null;
  organizationId: string;
  userId: string;
}

export interface CreateUserOrganizationLinkInput {
  data: CreateUserOrganizationLinkDataInput;
}

export interface CreateUserTagLinkInput {
  tagId: string;
  userId: string;
}

export interface CreateUserV2Input {
  accountData?: CreateUserV2Input_AccountData | null;
  addressAdministrativeArea?: string | null;
  addressLine0?: string | null;
  addressLocality?: string | null;
  addressPostalCode?: string | null;
  addressRegionCode?: string | null;
  company?: string | null;
  contactEmail?: string | null;
  firstName: string;
  integrationIsMember?: boolean | null;
  jobTitle?: string | null;
  lastName: string;
  primaryPhone?: string | null;
}

export interface CreateUserV2Input_AccountData {
  createWithRole: CreateUserV2Input_AccountData_CreateWithRole;
  email: string;
  emailInvitation: CreateUserV2Input_AccountData_EmailInvitationInput;
}

export interface CreateUserV2Input_AccountData_CreateWithRole {
  newOrganizationData?: CreateUserV2Input_AccountData_CreateWithRole_NewOrganizationData | null;
  organizationId?: string | null;
  userRole: EUserRole;
}

export interface CreateUserV2Input_AccountData_CreateWithRole_NewOrganizationData {
  isSponsor: boolean;
  name: string;
}

export interface CreateUserV2Input_AccountData_EmailInvitationInput {
  body?: string | null;
  sendEmailInvitation: boolean;
  subject?: string | null;
}

export interface CsvExportEventRegistrationsInput {
  eventId: string;
}

export interface CsvExportPermissionGroupMembersInput {
  _id: string;
}

export interface DateWindowSearch {
  windowEnd?: string | null;
  windowStart?: string | null;
  windowType: DateWindowType;
}

export interface Date_Selector {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: string[] | null;
  _lt?: string | null;
  _lte?: string | null;
}

export interface DeactivateMembershipTypeInput {
  id: string;
}

export interface DeactivateUserInput {
  userId: string;
}

export interface DefaultAttributeReviewConfigurationsForNewMembershipTypeInput {
  limit?: number | null;
  offset?: number | null;
  ownerKind: EAttributeOwnerKind;
}

export interface DeleteAnnouncementAndUpdatePostInput_V2 {
  postId: string;
}

export interface DeleteConferenceSessionInput {
  conferenceSessionId: string;
}

export interface DeleteEventTicketsInput {
  eventId: string;
  ticketIds: string[];
}

export interface DeleteGroupLibraryFolderInput {
  libraryFolderId: string;
}

export interface DeletePostInput_V2 {
  filter?: PostFilterInput | null;
  id?: string | null;
}

export interface DeleteTagInput {
  _id: string;
}

export interface DeleteUserGroupLinkInput {
  filter?: UserGroupLinkFilterInput | null;
  id?: string | null;
}

export interface DeleteUserNoteInput {
  id: string;
}

export interface DeleteUserOrganizationLinkInput {
  id?: string | null;
}

export interface DeleteUserTagLinkInput {
  tagId: string;
  userId: string;
}

export interface DisableAttributeSchemaInput {
  id: string;
}

export interface DuplicatePageInput {
  pageId: string;
}

export interface DuplicateSimpleEventInput {
  eventId: string;
}

export interface DynamicImageInput {
  ar?: string | null;
  crop?: string | null;
  facepad?: number | null;
  fill?: string | null;
  fillColor?: string | null;
  fit?: string | null;
  fm?: string | null;
  h?: number | null;
  mask?: string | null;
  w?: number | null;
}

export interface EditUserNotificationSoundPreferencesInput {
  shouldPlayNotificationSoundOnReceive?: boolean | null;
  shouldPlayNotificationSoundOnSend?: boolean | null;
  userId: string;
}

export interface EditUserRosterBenefitsInput {
  inheritsBenefits: boolean;
  userId: string;
}

export interface EmailMarketingIntegrationConstantContactInitializeWithCodeInput {
  code: string;
}

export interface EmailMarketingIntegrationConstantContactSetClientSecretAndSecretInput {
  clientId: string;
  clientSecret: string;
}

export interface EmailMarketingIntegrationConstantContactSyncUserIDInput {
  userID: string;
}

export interface EnableAttributeSchemaInput {
  id: string;
}

export interface EnsureEventTicketPurchaseIntentInput {
  ticketId: string;
}

export interface EnumFacetInput {
  allOf?: string[] | null;
  equalTo?: string | null;
  noneOf?: string[] | null;
  notEqualTo?: string | null;
}

export interface EventEmailPreferencesInput {
  activatedUserConfirmationEmail?: boolean | null;
  eventSummaryEmail?: boolean | null;
  nonActivatedUserConfirmationEmail?: boolean | null;
  twentyFourHourReminderEmail?: boolean | null;
}

export interface EventInput {
  _id: string;
}

/**
 * Either exactly 1, or none of `inPersonLocation` and `online` must be specified
 */
export interface EventVenueInput {
  inPersonLocation?: EventVenueInput_InPersonLocation | null;
  online?: EventVenueInput_OnlinePlatform | null;
  type?: EventVenueType | null;
}

export interface EventVenueInput_InPersonLocation {
  address?: EventVenueInput_InPersonLocation_Address | null;
  description?: string | null;
}

export interface EventVenueInput_InPersonLocation_Address {
  administrativeArea?: string | null;
  country?: string | null;
  locality?: string | null;
  name?: string | null;
  postalCode?: string | null;
  streetAddress?: string | null;
  streetAddressLine2?: string | null;
}

/**
 * Either exactly 1, or none of `tradewingPlatform` and `externalPlatform` must be specified
 */
export interface EventVenueInput_OnlinePlatform {
  externalPlatform?: EventVenueInput_OnlinePlatform_ExternalPlatform | null;
  tradewingPlatform?: EventVenueInput_OnlinePlatform_TradewingPlatform | null;
  type?: EOnlinePlatformType | null;
}

export interface EventVenueInput_OnlinePlatform_ExternalPlatform {
  additionalDetails?: RawDraftContentState | null;
  url?: string | null;
}

/**
 * Either exactly 1, or none of `preRecordedVideo` and `liveVideoStream` must be specified
 */
export interface EventVenueInput_OnlinePlatform_TradewingPlatform {
  liveVideoStream?: EventVenueInput_OnlinePlatform_TradewingPlatform_LiveVideoStream | null;
  preRecordedVideo?: EventVenueInput_OnlinePlatform_TradewingPlatform_PreRecordedVideo | null;
  type?: ETradewingPlatformStreamType | null;
}

export interface EventVenueInput_OnlinePlatform_TradewingPlatform_LiveVideoStream {
  canRecord?: boolean | null;
  isDialInEnabled?: boolean | null;
  maxParticipants?: number | null;
  shouldAutoRecord?: boolean | null;
  shouldSaveAsDefaultLiveStreamSettings?: boolean | null;
}

export interface EventVenueInput_OnlinePlatform_TradewingPlatform_PreRecordedVideo {
  videoFileId?: string | null;
}

export interface EventVisibilityInput {
  groupIds?: string[] | null;
  ticketIds?: string[] | null;
  type: EventVisibilityType;
  userIds?: string[] | null;
}

export interface Event_SponsorInput {
  organizationId: string;
}

export interface EventsInput {
  filter?: EventsInput_Filter | null;
  limit?: number | null;
  offset?: number | null;
  sort?: EventsInput_Sort | null;
}

export interface EventsInput_Filter {
  audienceGroupId?: string | null;
  dateWindow?: DateWindowSearch | null;
  includeCurrentUserInvolvedEventsOnly?: boolean | null;
  includeHappeningNowEvents?: boolean | null;
  searchQuery?: string | null;
}

export interface EventsInput_Sort {
  endDate?: SortOptions | null;
  startDate?: SortOptions | null;
}

export interface ExecuteAdminUserGroupLinkCsvUploadInput {
  csvUploadId: string;
  sendInviteEmailsOnInsert?: boolean | null;
  shouldCreateAccounts?: boolean | null;
  shouldRemoveAllOtherUsers?: boolean | null;
}

export interface ExecuteOrganizationCsvUploadInput {
  csvUploadId: string;
  matchField?: string | null;
  operator: OrganizationCsvUploadOperator;
}

export interface ExecuteRegistrationCsvUploadInput {
  createUsers?: boolean | null;
  csvUploadId: string;
  eventId: string;
  importType: CsvSubdocumentImportType;
  relativeUrl: string;
}

export interface ExecuteSpecificGroupMemberCsvUploadInput {
  csvUploadId: string;
  groupId: string;
  shouldRemoveAllOtherUsers?: boolean | null;
}

export interface ExecuteUserCsvUploadInput {
  createNewSponsors?: boolean | null;
  csvUploadId: string;
  matchField?: string | null;
  operator: UserCsvUploadOperator;
  sendInviteEmailsOnInsert?: boolean | null;
}

export interface ExecuteUserGroupLinkCsvUploadInput {
  csvUploadId: string;
}

export interface ExportAttendeesForConferenceSessionInput {
  conferenceSessionId: string;
}

export interface ExportAttendeesForSimpleEventInput {
  eventId: string;
}

export interface ExportGroupMembersInput {
  groupIds: string[];
}

export interface ExportOrganizationSearchInput {
  filter?: ExportOrganizationSearchInput_Filter | null;
}

export interface ExportOrganizationSearchInput_Filter {
  hidden?: boolean | null;
  isSponsor?: boolean | null;
  membershipStatuses?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface ExportRsvpsForEventInput {
  eventId: string;
}

export interface ExportUserSearchInput {
  filter?: ExportUserSearchInput_Filter | null;
}

export interface ExportUserSearchInput_Filter {
  accountStatusV2?: UserSearchInput_Filter_EAccountStatus[] | null;
  membershipStatusV3?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  role?: EUserRole[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface Float_Selector {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _lt?: number | null;
  _lte?: number | null;
}

export interface FollowSponsorsInput {
  data: FollowSponsorsInput_Data;
}

export interface FollowSponsorsInput_Data {
  sponsorIds: string[];
}

export interface FollowTagsInput {
  data: FollowTagsInput_Data;
}

export interface FollowTagsInput_Data {
  tagIds: string[];
}

export interface GetAddressAutoCompleteSuggestionsInput {
  query: string;
  sessionToken: string;
}

export interface GetAddressFromPlaceIdInput {
  placeId: string;
  sessionToken: string;
}

export interface GetCurrentUserDogfoodSessionTokenInput {
  sessionId: string;
}

export interface GetDraftImageProxyInput {
  imageId: string;
  source: EPrepImagesSource;
}

export interface GetDraftVideoFileInput {
  videoIds: string[];
}

export interface GetErrorsForOrganizationCsvUploadInput {
  data: GetErrorsForOrganizationCsvUploadInput_Data;
}

export interface GetErrorsForOrganizationCsvUploadInput_Data {
  organizationCsvUploadId: string;
}

export interface GetErrorsForRegistrationCsvUploadInput {
  data: GetErrorsForRegistrationCsvUploadInput_Data;
}

export interface GetErrorsForRegistrationCsvUploadInput_Data {
  registrationCsvUploadId: string;
}

export interface GetErrorsForUserCsvUploadInput {
  data: GetErrorsForUserCsvUploadInput_Data;
}

export interface GetErrorsForUserCsvUploadInput_Data {
  userCsvUploadId: string;
}

export interface GetErrorsForUserGroupLinkCsvUploadInput {
  data: GetErrorsForUserGroupLinkCsvUploadInput_Data;
}

export interface GetErrorsForUserGroupLinkCsvUploadInput_Data {
  userGroupLinkCsvUploadId: string;
}

export interface GetFilterableAttributeSchemasInput {
  filter?: GetFilterableAttributeSchemasInput_Filter | null;
  limit?: number | null;
  offset?: number | null;
}

export interface GetFilterableAttributeSchemasInput_Filter {
  ownerKind?: EAttributeOwnerKind | null;
}

export interface GetModerationItemsFilterInput {
  reason?: String_Selector | null;
  resolutionState?: String_Selector | null;
}

export interface GetModerationItemsInput {
  filter?: GetModerationItemsFilterInput | null;
  limit?: number | null;
  offset?: number | null;
  sort?: GetModerationItemsSortInput | null;
}

export interface GetModerationItemsSortInput {
  dateCreated?: SortOptions | null;
}

export interface GetOrganizationsSearchKeysInput {
  filter?: GetOrganizationsSearchKeysInput_Filter | null;
}

export interface GetOrganizationsSearchKeysInput_Filter {
  isMember?: boolean | null;
  isSponsor?: boolean | null;
}

export interface GetPostBySubPostIdInput {
  blogId?: string | null;
  pollId?: string | null;
  questionId?: string | null;
}

export interface GetProgrammableRendererTokenInput {
  sessionId: string;
  tradewingToken: string;
}

export interface GetRsvpSearchKeysInput {
  eventId: string;
}

export interface GetSetupIntentClientSecretForOrganizationInput {
  organizationId: string;
}

export interface GetSetupIntentClientSecretForUserInput {
  userId: string;
}

export interface GetTenantStripeCustomerInput {
  stripeCustomerId: string;
}

export interface GetUniqueEventDatesInput {
  endDate: string;
  startDate: string;
  timezone?: string | null;
}

export interface GetUsersNotInPermissionGroupInput {
  limit?: number | null;
  offset?: number | null;
  permissionGroupId: string;
  textQuery?: string | null;
}

export interface GetValidAllowedViewersForNewCustomAttributeInput {
  ownerKind: EAttributeOwnerKind;
}

export interface GetVideoFileInput {
  videoFileId: string;
}

export interface GroupEventDraftFeedInput {
  filter?: GroupEventDraftFeedInput_Filter | null;
  groupId: string;
  limit: number;
  offset: number;
  sort?: EventsInput_Sort | null;
}

export interface GroupEventDraftFeedInput_Filter {
  dateWindow?: DateWindowSearch | null;
  searchQuery?: string | null;
}

export interface GroupFilterInput {
  _isPublic?: GroupIsPublicFilterInput | null;
  _isVisible?: GroupIsVisibleFilterInput | null;
  _withTextSearch?: GroupWithTextSearchFilterInput | null;
  isGlobalSingleton?: Boolean_Selector | null;
}

export interface GroupInput {
  _id?: string | null;
}

export interface GroupIsPublicFilterInput {
  active?: boolean | null;
}

export interface GroupIsVisibleFilterInput {
  active?: boolean | null;
}

export interface GroupPermissionOptionsV2Data {
  parentGroupId?: string | null;
}

export interface GroupPermissionOptionsV2Input {
  data: GroupPermissionOptionsV2Data;
}

export interface GroupSortInput {
  name?: SortOptions | null;
}

export interface GroupUserSearchInput {
  filter?: GroupUserSearchInput_Filter | null;
  limit: number;
  offset: number;
}

export interface GroupUserSearchInput_Filter {
  groupId?: string | null;
  searchText?: string | null;
}

export interface GroupWithTextSearchFilterInput {
  searchText: string;
}

export interface GroupsInput {
  filter?: GroupFilterInput | null;
  limit?: number | null;
  offset?: number | null;
  sort?: GroupSortInput | null;
}

export interface HappeningNowInput {
  limit: number;
  offset: number;
}

export interface HasRegularRoleFilterInput {
  active: boolean;
}

export interface HeadingInput {
  name?: string | null;
  options?: HeadingOptionsInput | null;
  subtitle?: string | null;
  title?: string | null;
  variant?: number | null;
}

export interface HeadingOptionsInput {
  backgroundColor?: EPageContentBackgroundColor | null;
  textAlign?: EHeadingTextAlign | null;
}

export interface HeroInput {
  body?: string | null;
  imageId?: string | null;
  linkHref?: string | null;
  linkPageId?: string | null;
  linkSource?: ELinkSource | null;
  linkText?: string | null;
  name?: string | null;
  options?: HeroOptionsInput | null;
  subtitle?: string | null;
  title?: string | null;
  variant?: number | null;
}

export interface HeroOptionsInput {
  isLinkEnabled?: boolean | null;
  linkBehavior?: EHeroLinkBehavior | null;
}

export interface ImageGroupInput {
  images: ImageGroup_ImageInput[];
  name?: string | null;
  options?: ImageGroupOptionsInput | null;
  variant?: number | null;
}

export interface ImageGroupOptionsInput {
  backgroundColor?: EPageContentBackgroundColor | null;
  imageAlignment?: EImageGroupImageAlignment | null;
  imageHeight?: number | null;
}

export interface ImageGroup_ImageInput {
  imageId?: string | null;
  linkHref?: string | null;
  linkPageId?: string | null;
  linkSource?: ELinkSource | null;
  name?: string | null;
}

export interface Int_Selector {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _lt?: number | null;
  _lte?: number | null;
}

export interface IsOrganizationNameUniqueInput {
  name: string;
}

export interface IsUserEmailUniqueInput {
  email: string;
}

export interface JoinGroupsInput {
  data: JoinGroupsInput_Data;
}

export interface JoinGroupsInput_Data {
  groupIds: string[];
}

export interface KeyAndAdditionalSponsorInputs {
  additionalSponsorInputs?: NewOrExistingSponsorInput[] | null;
  keySponsorInput?: NewOrExistingSponsorInput | null;
}

export interface LibraryFolderContentsInput {
  libraryFolderId?: string | null;
  limit?: number | null;
  offset?: number | null;
  searchTerm?: string | null;
}

export interface LibraryFolderVersionFileLinkFilterInput {
  _and?: (LibraryFolderVersionFileLinkFilterInput | null)[] | null;
  _id?: String_Selector | null;
  _inLibrary?: LibraryFolderVersionFileLinkInLibraryFilterInput | null;
  _not?: LibraryFolderVersionFileLinkFilterInput | null;
  _or?: (LibraryFolderVersionFileLinkFilterInput | null)[] | null;
  dateCreated?: Date_Selector | null;
  libraryFolderId?: String_Selector | null;
  tenantId?: String_Selector | null;
  versionFileId?: String_Selector | null;
}

export interface LibraryFolderVersionFileLinkInLibraryFilterInput {
  fileName?: string | null;
  libraryId: string;
}

export interface LibraryFolderVersionFileLinkSelectorUniqueInput {
  _id?: string | null;
  documentId?: string | null;
  slug?: string | null;
}

export interface LibraryFolderVersionFileLinkSortInput {
  _id?: SortOptions | null;
  dateCreated?: SortOptions | null;
  libraryFolderCache?: SortOptions | null;
  libraryFolderId?: SortOptions | null;
  tenantId?: SortOptions | null;
  versionFileId?: SortOptions | null;
}

/**
 * linkGroupsToParent
 */
export interface LinkGroupsToParentData {
  childGroupIds: string[];
  parentGroupId: string;
}

export interface LinkGroupsToParentInput {
  data: LinkGroupsToParentData;
}

export interface LinkToRecordingInput {
  url?: string | null;
}

export interface LoginWithCredentialsInput {
  password: string;
  username: string;
}

export interface ManualPermissionGroupAddUsersInput {
  data: ManualPermissionGroupAddUsersInputData;
  permissionGroupId: string;
}

export interface ManualPermissionGroupAddUsersInputData {
  userIds: string[];
}

export interface ManualPermissionGroupRemoveUsersInput {
  data: ManualPermissionGroupRemoveUsersInputData;
  permissionGroupId: string;
}

export interface ManualPermissionGroupRemoveUsersInputData {
  userIds: string[];
}

export interface MarkAnnouncementsAsReadInput {
  groupId?: string | null;
}

export interface MembershipOrderInput {
  membershipOrderId: string;
}

export interface MembershipOrderStatusChangedInput {
  membershipOrderId: string;
}

export interface MembershipTypeAttributeReviewConfigurationInput {
  attributeSchema?: CreateAttributeSchemaInput | null;
  attributeSchemaId?: string | null;
  isRequired: boolean;
}

export interface MembershipTypeInput {
  id: string;
}

export interface MembershipTypesInput {
  filter?: MembershipTypesInput_Filter | null;
  limit: number;
  offset: number;
}

export interface MembershipTypesInput_Filter {
  includeCorporateMemberships?: boolean | null;
  includeIndividualMemberships?: boolean | null;
  isActive?: boolean | null;
  isPublic?: boolean | null;
}

export interface MockMessage_Input {
  organizationId: string;
}

export interface MockOrganizationLeads_Input {
  organizationId: string;
}

export interface ModerationItemFilterInput {
  _and?: (ModerationItemFilterInput | null)[] | null;
  _id?: String_Selector | null;
  _not?: ModerationItemFilterInput | null;
  _or?: (ModerationItemFilterInput | null)[] | null;
  commentId?: String_Selector | null;
  dateCreated?: Date_Selector | null;
  postId?: String_Selector | null;
  reason?: String_Selector | null;
  reportedByUserId?: String_Selector | null;
  resolutionState?: String_Selector | null;
  tenantId?: String_Selector | null;
}

export interface ModifyMembershipTermEndDateInput {
  endDate?: string | null;
  membershipTermId: string;
}

export interface Morgoth_DeleteUsersInput {
  userIds: string[];
}

export interface Morgoth_QueueMemberManagementMorgothActionInput {
  jobName: MemberManagementMorgothActionJobName;
}

export interface Morgoth_RunMemberManagementCronInput {
  job: EMorgoth_RunMemberManagementCronJob;
}

export interface Morgoth_SendUnusedSponsorBenefitsTestEmailInput {
  organizationId: string;
}

export interface Morgoth_SetMembershipSituationForOwnerInput {
  membershipSituation: EMembershipSituation;
  membershipTypeId?: string | null;
  monthlyPaymentsRemaining?: number | null;
  organizationId?: string | null;
  userId?: string | null;
}

export interface Morgoth_ShiftAllMembershipTermsForOwnerInput {
  numberOfDays: number;
  organizationId?: string | null;
  userId?: string | null;
}

export interface Morgoth_SyncAlgoliaIndexInput {
  includeAllTenants?: boolean | null;
  indexToSync: EMorgoth_SyncAlgoliaIndexInput_IndexToSync;
}

export interface MoveGroupLibraryFilesInput {
  groupId: string;
  toFolderId: string;
  versionFileIds: string[];
}

export interface MoveGroupLibraryFolderInput {
  libraryFolderId: string;
  toLibraryFolderId: string;
}

export interface MultiOrganizationInput {
  filter?: OrganizationFilterInput | null;
  limit?: number | null;
  offset?: number | null;
  sort?: OrganizationSortInput | null;
}

export interface MultiPostInputV2 {
  filter?: MultiPostInputV2_Filter | null;
  limit?: number | null;
  offset?: number | null;
  sort?: MultiPostInputV2_Sort | null;
}

export interface MultiPostInputV2_Filter {
  _hideDeleted?: MultiPostInputV2_Filter_HideDeleted | null;
  _onBlogs?: MultiPostInputV2_Filter_OnBlogs | null;
  _withDraftBlogs?: MultiPostInputV2_Filter_WithDraftBlogs | null;
  groupId?: MultiPostInputV2_Filter_String_Eq | null;
  pinned?: MultiPostInputV2_Filter_Boolean_Eq | null;
}

export interface MultiPostInputV2_Filter_Boolean_Eq {
  _eq: boolean;
}

export interface MultiPostInputV2_Filter_HideDeleted {
  hide: boolean;
}

export interface MultiPostInputV2_Filter_OnBlogs {
  isBlog: boolean;
}

export interface MultiPostInputV2_Filter_String_Eq {
  _eq: string;
}

export interface MultiPostInputV2_Filter_WithDraftBlogs {
  userId: string;
}

export interface MultiPostInputV2_Sort {
  dateCreated: boolean;
}

export interface MultiTagFilterInput {
  searchText?: string | null;
}

export interface MultiTagInput {
  filter?: MultiTagFilterInput | null;
  limit?: number | null;
  offset?: number | null;
}

export interface MultiUserInput {
  enableCache?: boolean | null;
  enableTotal?: boolean | null;
  filter?: UserFilterInput | null;
  limit?: number | null;
  offset?: number | null;
  search?: string | null;
  sort?: UserSortInput | null;
  terms?: JSON | null;
}

export interface MultiUserOrganizationLinkInput {
  filter?: UserOrganizationLinkFilterInput | null;
  limit?: number | null;
  offset?: number | null;
}

export interface MuteOrUnmuteDialInUserInput {
  callUUID: string;
  mute: boolean;
}

export interface MyDraftPostsInput {
  limit?: number | null;
  offset?: number | null;
}

export interface NavigationInput {
  isRestrictionIgnored?: boolean | null;
  isUnpublishedVersion?: boolean | null;
}

export interface NavigationItemInput {
  children?: NavigationItemInput[] | null;
  defaultItemIdentifier?: EDefaultNavigationItemIdentifier | null;
  href?: string | null;
  label?: string | null;
  pageId?: string | null;
  restriction?: ENavigationItemRestriction | null;
}

export interface NewOrExistingSpeakerInput {
  existingUserId?: string | null;
  isHidden: boolean;
  newSpeaker?: NewSpeakerOrModeratorInput | null;
}

/**
 * Exactly 1 of these fields must be specified
 */
export interface NewOrExistingSponsorInput {
  existingOrganizationId?: string | null;
  newSponsor?: CreateCompanyInput | null;
}

export interface NewSpeakerOrModeratorInput {
  accountEmail: string;
  company?: string | null;
  firstName: string;
  jobTitle?: string | null;
  lastName: string;
  shouldSendInvitationEmail: boolean;
}

export interface NextAvailableGroupPriorityData {
  parentGroupId?: string | null;
}

export interface NextAvailableGroupPriorityInput {
  data: NextAvailableGroupPriorityData;
}

export interface OidcClientData {
  _id: string;
}

export interface OidcClientInput {
  data: OidcClientData;
}

export interface OnPaymentMethodAddedInput {
  organizationId?: string | null;
  userId?: string | null;
}

export interface OrganizationEventDraftFeedInput {
  filter?: OrganizationEventDraftFeedInput_Filter | null;
  limit: number;
  offset: number;
  organizationId: string;
  sort?: EventsInput_Sort | null;
}

export interface OrganizationEventDraftFeedInput_Filter {
  dateWindow?: DateWindowSearch | null;
  searchQuery?: string | null;
}

export interface OrganizationEventFeedInput {
  filter?: OrganizationEventFeedInput_Filter | null;
  limit: number;
  offset: number;
  organizationId: string;
  sort?: EventsInput_Sort | null;
}

export interface OrganizationEventFeedInput_Filter {
  dateWindow?: DateWindowSearch | null;
  searchQuery?: string | null;
}

export interface OrganizationExcludeHiddenFilterInput {
  active: boolean;
}

export interface OrganizationFilterInput {
  _excludeHidden?: OrganizationExcludeHiddenFilterInput | null;
  _isSponsor?: boolean | null;
  _withTextSearch?: OrganizationWithTextSearchFilterInput | null;
}

export interface OrganizationLeadExportInput {
  conferenceBoothId?: string | null;
  organizationId: string;
}

export interface OrganizationLeadsByOrganization_Filter {
  boothId?: string | null;
  textSearch?: string | null;
}

export interface OrganizationLeadsInput {
  filter?: OrganizationLeadsByOrganization_Filter | null;
  limit?: number | null;
  offset?: number | null;
  organizationId: string;
}

export interface OrganizationRosterExistingUser {
  role: EUserRole;
  userId: string;
}

export interface OrganizationRosterNewUser {
  email: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
}

export interface OrganizationSearchInput {
  filter?: OrganizationSearchInput_Filter | null;
  limit: number;
  offset: number;
}

export interface OrganizationSearchInput_Filter {
  hidden?: boolean | null;
  isSponsor?: boolean | null;
  membershipStatuses?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface OrganizationSortInput {
  displayName?: SortOptions | null;
  name?: SortOptions | null;
}

export interface OrganizationSponsoredEventFeedInput {
  filter?: OrganizationSponsoredEventFeedInput_Filter | null;
  limit: number;
  offset: number;
  organizationId: string;
  sort?: EventsInput_Sort | null;
}

export interface OrganizationSponsoredEventFeedInput_Filter {
  dateWindow?: DateWindowSearch | null;
  eventStatus?: EventStatus[] | null;
  searchText?: string | null;
}

export interface OrganizationWithTextSearchFilterInput {
  searchText: string;
}

export interface Organization_DescriptiveResource_DataInput {
  image?: Organization_DescriptiveResource_DataInput_Image | null;
  video?: Organization_DescriptiveResource_DataInput_Video | null;
}

export interface Organization_DescriptiveResource_DataInput_Image {
  imageFileId: string;
}

export interface Organization_DescriptiveResource_DataInput_Video {
  videoFileId: string;
}

export interface PageContentInput {
  blockGroup?: BlockGroupInput | null;
  carousel?: CarouselInput | null;
  heading?: HeadingInput | null;
  hero?: HeroInput | null;
  imageGroup?: ImageGroupInput | null;
  richText?: RichTextInput | null;
}

export interface PageInput {
  id: string;
  isSlug?: boolean | null;
  isUnpublishedVersion?: boolean | null;
}

export interface PagesInput {
  after?: string | null;
  before?: string | null;
  filter?: PagesInputFilter | null;
  first?: number | null;
  last?: number | null;
}

export interface PagesInputFilter {
  includeUnpublished?: boolean | null;
  restriction?: EPageRestriction | null;
  searchText?: string | null;
}

export interface PatchMembershipTypeDetailsInput {
  id: string;
  patch: PatchMembershipTypeDetailsInput_Patch;
}

export interface PatchMembershipTypeDetailsInput_Patch {
  description?: RawDraftContentState | null;
  name?: string | null;
  numberOfSeats?: number | null;
  publiclyVisible?: boolean | null;
}

/**
 * To remove any previously set field, pass it as null
 * Passing a field as undefined will not change it
 */
export interface PatchTenantContactInfoInput {
  addressAdministrativeArea?: string | null;
  addressLine0?: string | null;
  addressLocality?: string | null;
  addressPostalCode?: string | null;
  addressRegionCode?: string | null;
  email?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  phoneNumber?: string | null;
  twitter?: string | null;
  youtube?: string | null;
}

export interface PayMembershipOrderWithCreditInput {
  membershipOrderId: string;
}

export interface PayMembershipOrderWithOtherInput {
  externalNote?: string | null;
  membershipOrderId: string;
  paymentDate: string;
  paymentReceived: Money;
  referenceNumber?: string | null;
}

export interface PermissionConfigInput {
  allowList: boolean;
  spec: PermissionConfigInput_Spec;
}

/**
 * Only one of these can be set
 */
export interface PermissionConfigInput_Spec {
  defaultPermission?: EDefaultPermissionConfig | null;
  groupId?: string | null;
  permissionGroupIds?: string[] | null;
  userIds?: string[] | null;
}

export interface PermissionGroupInput {
  _id?: string | null;
}

export interface PermissionGroupUsersInput {
  limit?: number | null;
  offset?: number | null;
  textSearches?: string | null;
}

export interface PermissionGroupsInput {
  limit?: number | null;
  offset?: number | null;
  textQuery?: string | null;
}

export interface PostFeedInputV2 {
  cursor?: string | null;
  excludePinned?: boolean | null;
  groupId?: string | null;
  organizationId?: string | null;
  tagId?: string | null;
}

export interface PostFilterInput {
  _hideDeleted?: PostHideDeletedFilterInput | null;
  groupId?: PostFilterInput_String_Eq | null;
  pinned?: PostFilterInput_Boolean_Eq | null;
  questionId?: PostFilterInput_String_Eq | null;
}

export interface PostFilterInput_Boolean_Eq {
  _eq?: boolean | null;
}

export interface PostFilterInput_String_Eq {
  _eq?: string | null;
}

export interface PostHideDeletedFilterInput {
  hide?: boolean | null;
}

export interface PostsByIdsInput {
  postIds: string[];
}

export interface PrepConferenceBoothResourceDocumentUploadInput {
  conferenceBoothId: string;
  documentUploadData: PrepConferenceBoothResourceDocumentUploadInput_DocumentData[];
}

export interface PrepConferenceBoothResourceDocumentUploadInput_DocumentData {
  contentType: string;
  name: string;
}

export interface PrepConferenceSessionResourceDocumentUploadInput {
  conferenceSessionId: string;
  documentUploadData: PrepConferenceSessionResourceDocumentUploadInput_DocumentData[];
}

export interface PrepConferenceSessionResourceDocumentUploadInput_DocumentData {
  contentType: string;
  name: string;
}

export interface PrepImagesAtSourceInput {
  numImages: number;
  source: EPrepImagesSource;
}

export interface PrepOrganizationImageUploadInput {
  organizationId: string;
}

export interface PrepSimpleEventResourceDocumentUploadInput {
  documentUploadData: PrepSimpleEventResourceDocumentUploadInput_DocumentData[];
  simpleEventId: string;
}

export interface PrepSimpleEventResourceDocumentUploadInput_DocumentData {
  contentType: string;
  name: string;
}

export interface PrepVersionFilesAtSourceInputV2 {
  fileInfo: PrepVersionFilesAtSourceInputV2_FileInfo[];
  source: EPrepVersionFilesSource;
}

export interface PrepVersionFilesAtSourceInputV2_FileInfo {
  name: string;
  size: number;
  type: string;
}

export interface PrepVideoUploadInput {
  entityId: PrepVideoUploadInput_EntityId;
  filename?: string | null;
}

/**
 * TODO(pranav): Denormalize the eventId onto the videoFile
 * for easier permissioning
 * TODO(pranav): After creating a simpleEvent, session, or booth,
 * update the metadata of the videoFiles attached to them with
 * the event, session, or booth id
 * 
 * When attaching videos to a new simpleEvent, pass `null`
 * for simpleEventId and `undefined` for the other fields
 * 
 * Pass the `conferenceEventId` if the video is being attached
 * to a conference session or conference booth.
 * When uploading a video for a new session or booth, pass
 * `null` for either `conferenceSessionId` or `conferenceBoothId`.
 */
export interface PrepVideoUploadInput_EntityId {
  conferenceBoothId?: string | null;
  conferenceEventId?: string | null;
  conferenceSessionId?: string | null;
  organizationId?: string | null;
  simpleEventId?: string | null;
}

export interface ProfileAttributeSchemaWithNameExistsInput {
  name: string;
  ownerKind: EAttributeOwnerKind;
}

export interface PublishDraftPostInput {
  postId: string;
}

export interface PublishEventInput {
  eventId: string;
}

export interface PurchaseFreeEventTicketInput {
  ticketId: string;
}

export interface QueueIntegrationJobInput {
  jobType: IntegrationJobType;
}

export interface QueueMembershipTermInput {
  endDate?: string | null;
  firstRenewalCycleFree?: boolean | null;
  membershipTypeId: string;
  organizationId?: string | null;
  paymentInterval: PaymentInterval;
  startDate: string;
  userId?: string | null;
}

export interface RegistrationInput {
  ticketId: string;
  userId: string;
}

export interface RegistrationsInput {
  filter?: Registrations_Filter | null;
  limit?: number | null;
  offset?: number | null;
}

export interface Registrations_Filter {
  refunded?: boolean | null;
  ticketId?: string | null;
}

export interface RelevantMembershipTermsInput {
  includeRecentlyLapsed?: boolean | null;
}

export interface RemoveConferenceBoothResourcesInput {
  conferenceBoothId: string;
  resourceIds: string[];
}

export interface RemoveConferenceSessionResourcesInput {
  conferenceSessionId: string;
  resourceIds: string[];
}

export interface RemoveFilesFromGroupLibraryInput {
  groupId: string;
  versionFileIds: string[];
}

export interface RemovePaymentMethodInput {
  paymentMethodId: string;
}

export interface RemoveSimpleEventResourcesInput {
  resourceIds: string[];
  simpleEventId: string;
}

export interface RemoveUserFromOrganizationRosterInput {
  organizationId: string;
  userId: string;
}

export interface RequestSSORedirectUrlInput {
  nonce?: string | null;
  state?: string | null;
  tradewingRedirectURL: string;
}

export interface RequestYourMembershipSSORedirectURLInput {
  twRedirectUrl: string;
}

export interface RetrieveAccountIdFromAccessTokenInput {
  accessToken: string;
}

export interface RichTextInput {
  name?: string | null;
  options?: RichTextOptionsInput | null;
  rawDraftContentState?: RawDraftContentState | null;
  variant?: number | null;
}

export interface RichTextOptionsInput {
  backgroundColor?: EPageContentBackgroundColor | null;
}

export interface RosterInput {
  after?: string | null;
  first: number;
  query?: string | null;
}

export interface RunIntegrationSyncForUserInput {
  integrationId: string;
}

export interface SaveEventInteractionInput {
  eventMetadata: SaveEventInteractionInput_EventMetadata;
  interaction: EventInteraction;
}

/**
 * Exactly one of the following must be specified
 */
export interface SaveEventInteractionInput_EventMetadata {
  conferenceBoothId?: string | null;
  conferenceSessionId?: string | null;
  simpleEventId?: string | null;
}

export interface SearchUsersToAddToOrganizationRosterInput {
  after?: string | null;
  excludeIds: string[];
  first: number;
  organizationId: string;
  query: string;
}

export interface SeedTenantDemoInstanceData {
  placeholder?: boolean | null;
}

export interface SeedTenantDemoInstanceInput {
  data: SeedTenantDemoInstanceData;
}

export interface SendBulkEmailInvitationsV3Input {
  emailData: SendBulkEmailInvitationsV3Input_EmailData;
  filter?: SendBulkEmailInvitationsV3Input_Filter | null;
  userIds?: string[] | null;
}

export interface SendBulkEmailInvitationsV3Input_EmailData {
  body: string;
  subject: string;
}

export interface SendBulkEmailInvitationsV3Input_Filter {
  accountStatusV2?: UserSearchInput_Filter_EAccountStatus[] | null;
  excludeUserIds?: string[] | null;
  membershipStatusV3?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  role?: EUserRole[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface SendCurrentUserSponsoredEventTestEmailInput {
  eventId: string;
}

export interface SendCurrentUserTestEventEmailInput {
  emailType: EventEmailType;
  eventId: string;
}

export interface SendCurrentUserTestInvitationEmailInput {
  body: string;
  organizationIdForSponsorAdmin?: string | null;
  subject: string;
}

export interface SendCurrentUserTestWelcomeEmailInput {
  body: string;
  subject: string;
}

export interface SendTestSponsorUpgradeRequestEmailInput {
  organizationId: string;
}

export interface SendUserInvitationEmailInput {
  body: string;
  subject: string;
  userId: string;
}

export interface SendVendorInvitationEmailInput {
  organizationId: string;
  userId: string;
}

export interface SetClientInitiatedPaymentProcessForMembershipOrderInput {
  membershipOrderId: string;
}

export interface SetEventSponsorShipData {
  firstTierSponsorships: Event_SponsorInput[];
  secondTierSponsorships: Event_SponsorInput[];
  thirdTierSponsorships: Event_SponsorInput[];
}

export interface SetEventSponsorshipInput {
  data: SetEventSponsorShipData;
  eventId: string;
}

export interface SetEventSponsorshipTiersConfigData {
  firstTier?: SetEventSponsorshipTiersConfigData_Tier | null;
  secondTier?: SetEventSponsorshipTiersConfigData_Tier | null;
  thirdTier?: SetEventSponsorshipTiersConfigData_Tier | null;
}

export interface SetEventSponsorshipTiersConfigData_Tier {
  displayName: string;
}

export interface SetEventSponsorshipTiersConfigInput {
  data: SetEventSponsorshipTiersConfigData;
  eventId: string;
}

export interface SetFreeSponsorTierOverridesForTenantInput {
  canViewAndDownloadLeads?: boolean | null;
  canViewAndReplyToDirectMessage?: boolean | null;
  isSponsoredEventsPageVisible?: boolean | null;
  isUpgradeCardVisible?: boolean | null;
}

export interface SetIntegrationDebuggingInput {
  debuggingEnabled: boolean;
}

export interface SetIntegrationIdForUserInput {
  id: string;
  integrationId: string;
}

export interface SetMembershipOrderRenewalModalSeenInput {
  membershipOrderId: string;
}

export interface SetStripePaymentIntentFutureUsageInput {
  _id: string;
  setupFutureUsage: PaymentIntent_SetupFutureUsage;
}

export interface SetStripePaymentTransactionExternalNoteInput {
  _id: string;
  externalNote: string;
}

export interface SignUpProspectUserInput {
  email: string;
  firstName: string;
  lastName: string;
  redirect?: string | null;
}

export interface SimpleEventResourcesInput {
  limit: number;
  offset: number;
}

export interface SingleLibraryFolderInputV2 {
  id: string;
}

export interface SingleLibraryFolderVersionFileLinkInput {
  allowNull?: boolean | null;
  enableCache?: boolean | null;
  filter?: LibraryFolderVersionFileLinkFilterInput | null;
  groupId: string;
  id?: string | null;
  search?: string | null;
  selector?: LibraryFolderVersionFileLinkSelectorUniqueInput | null;
  sort?: LibraryFolderVersionFileLinkSortInput | null;
  versionFileId?: string | null;
}

export interface SingleOrganizationInput {
  id?: string | null;
}

export interface SinglePostInputV2 {
  id?: string | null;
}

export interface SingleTagInput {
  _id: string;
}

export interface SingleUseAttributeSchemaForEntityWithNameExistsInput {
  entityId: string;
  name: string;
  ownerKind: EAttributeOwnerKind;
}

export interface SingleUserInput {
  allowNull?: boolean | null;
  enableCache?: boolean | null;
  filter?: UserFilterInput | null;
  id?: string | null;
  search?: string | null;
  selector?: UserSelectorUniqueInput | null;
  sort?: UserSortInput | null;
}

export interface SponsorTierLogsInput {
  organizationId: string;
}

export interface StartConferenceBoothRecordingInput {
  conferenceBoothId: string;
}

export interface StartConferenceSessionRecordingInput {
  conferenceSessionId: string;
}

export interface StopConferenceBoothRecordingInput {
  conferenceBoothId: string;
}

export interface StopConferenceSessionRecordingInput {
  conferenceSessionId: string;
}

export interface StringFacetInput {
  contains?: string | null;
  doesNotContain?: string | null;
  endsWith?: string | null;
  equalTo?: string | null;
  notEqualTo?: string | null;
  startsWith?: string | null;
}

export interface String_Selector {
  _eq?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _neq?: string | null;
  _nin?: string[] | null;
}

export interface SubgroupsFilter {
  includeAll?: boolean | null;
}

export interface SubgroupsInput {
  filter?: SubgroupsFilter | null;
}

export interface TagsFollowedByUserInput {
  limit: number;
  offset: number;
}

export interface TagsNotFollowedByUserInput {
  limit: number;
  offset: number;
}

export interface TenantStripeCustomerContactInput {
  addressAdministrativeArea?: string | null;
  addressLine0?: string | null;
  addressLine1?: string | null;
  addressLocality?: string | null;
  addressPostalCode?: string | null;
  addressRegionCode?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

export interface TenantStripeCustomerInput {
  contactInfo?: TenantStripeCustomerContactInput | null;
  description?: string | null;
  name?: string | null;
}

export interface TerminateMembershipTermInput {
  membershipTermId: string;
}

export interface TicketAttributeReviewConfigurationInput {
  attributeSchema?: CreateAttributeSchemaInput | null;
  attributeSchemaId?: string | null;
  isRequired: boolean;
}

export interface TicketInput {
  _id: string;
}

export interface TopLevelGroupsData {
  filter: TopLevelGroupsFilter;
  limit?: number | null;
  offset?: number | null;
}

/**
 * topLevelGroups
 */
export interface TopLevelGroupsFilter {
  searchTerm?: string | null;
}

export interface TopLevelGroupsInput {
  data: TopLevelGroupsData;
}

export interface UniqueSessionDateInput {
  timeZone: string;
}

/**
 * unlinkChildGroupFromParent
 */
export interface UnlinkChildGroupFromParentData {
  childGroupId: string;
}

export interface UnlinkChildGroupFromParentInput {
  data: UnlinkChildGroupFromParentData;
}

export interface UpdateAdDataInput {
  adImageIdV2?: string | null;
  body?: string | null;
  callToAction?: string | null;
  published?: boolean | null;
  registerInterestTitle?: string | null;
  title?: string | null;
  url?: string | null;
}

export interface UpdateAdInput {
  data: UpdateAdDataInput;
  id: string;
}

export interface UpdateAnswerDataInput_V2 {
  body: RawDraftContentState;
  embeddedImageIds?: string[] | null;
  tagIds?: string[] | null;
  versionFileIds?: string[] | null;
}

export interface UpdateAnswerInput_V2 {
  answerPostId: string;
  data: UpdateAnswerDataInput_V2;
}

export interface UpdateAttributeSchemaInput {
  attributeSchemaId: string;
  data: UpdateAttributeSchemaInput_Data;
}

export interface UpdateAttributeSchemaInput_Data {
  allowedEditors?: EAttributeAllowedEditorKind[] | null;
  defaultAllowedViewers?: EAttributeAllowedViewerKind[] | null;
  isAssociatedWithExternalAMSField?: boolean | null;
  isSearchable?: boolean | null;
  name?: string | null;
  options?: UpdateAttributeSchemaInput_Data_Option[] | null;
}

export interface UpdateAttributeSchemaInput_Data_Option {
  id?: string | null;
  label: string;
}

export interface UpdateAttributesInput {
  attributes: UpdateAttributesInput_Attribute[];
}

export interface UpdateAttributesInput_Attribute {
  allowedViewers?: EAttributeAllowedViewerKind[] | null;
  attributeId: string;
  value?: CustomAttributeData | null;
}

export interface UpdateBlogDataInput_V2 {
  blogBannerImageId?: string | null;
  content: RawDraftContentState;
  groupId?: string | null;
  published?: boolean | null;
  scheduledPublishDate?: string | null;
  tagIds?: string[] | null;
  title: string;
}

export interface UpdateBlogInput_V2 {
  blogPostId: string;
  data: UpdateBlogDataInput_V2;
  isAnnouncement?: boolean | null;
}

export interface UpdateCommentDataInput {
  body?: RawDraftContentState | null;
  bodyPlainText?: string | null;
  datePublished?: string | null;
  embeddedImageIds?: (string | null)[] | null;
  moderationItemId?: string | null;
  streamId?: string | null;
  streamNotificationActivityId?: string | null;
  versionFileIds?: (string | null)[] | null;
}

export interface UpdateCommentInput {
  data: UpdateCommentDataInput;
  filter?: CommentFilterInput | null;
  id?: string | null;
}

export interface UpdateConferenceBoothInput {
  conferenceBoothId: string;
  data: UpdateConferenceBoothInput_Data;
}

export interface UpdateConferenceBoothInput_Data {
  alternativeVideoFileId?: string | null;
  canonicalVideoFileId?: string | null;
  description?: string | null;
  imageId?: string | null;
  linkToRecording?: LinkToRecordingInput | null;
  moderators?: NewOrExistingSpeakerInput[] | null;
  specialOffer?: UpdateConferenceBoothInput_Data_SpecialOffer | null;
  venue?: EventVenueInput | null;
}

export interface UpdateConferenceBoothInput_Data_SpecialOffer {
  adImageId: string;
  body: string;
  callToAction: string;
  registerInterestTitle: string;
  title: string;
  url: string;
}

export interface UpdateConferenceEventInput {
  data: UpdateConferenceEventInput_Data;
  eventId: string;
}

export interface UpdateConferenceEventInput_Data {
  bannerImageId?: string | null;
  description?: RawDraftContentState | null;
  emailPreferences?: EventEmailPreferencesInput | null;
  endDate?: string | null;
  externalRegistrationUrl?: string | null;
  inPersonLocation?: EventVenueInput_InPersonLocation | null;
  isExternalRegistrationUrlActive?: boolean | null;
  name?: string | null;
  shortDescription?: string | null;
  startDate?: string | null;
  timeZone?: string | null;
  venueType?: EventVenueType | null;
  visibility?: EventVisibilityInput | null;
}

export interface UpdateConferenceSessionInput {
  conferenceSessionId: string;
  patch: UpdateConferenceSessionInput_Patch;
}

export interface UpdateConferenceSessionInput_Patch {
  alternativeVideoFileId?: string | null;
  canonicalVideoFileId?: string | null;
  description?: string | null;
  endDate?: string | null;
  imageId?: string | null;
  linkToRecording?: LinkToRecordingInput | null;
  speakers?: NewOrExistingSpeakerInput[] | null;
  sponsorData?: KeyAndAdditionalSponsorInputs | null;
  startDate?: string | null;
  title?: string | null;
  venue?: EventVenueInput | null;
  visibility?: EventVisibilityInput | null;
}

export interface UpdateCustomLinkInput {
  text?: string | null;
  url: string;
}

export interface UpdateCustomWelcomeEmailInput {
  customWelcomeEmail: UpdateCustomWelcomeEmailInput__Data;
  hasCustomWelcomeEmail: boolean;
  id: string;
}

export interface UpdateCustomWelcomeEmailInput__Data {
  body: string;
  subject: string;
}

export interface UpdateDraftPollDataInput_V2 {
  body: RawDraftContentState;
  embeddedImageIds?: string[] | null;
  groupId?: string | null;
  options: CreatePollOptionDataInput[];
  scheduledPublishDate?: string | null;
  tagIds?: string[] | null;
  title: string;
  type: string;
  versionFileIds?: string[] | null;
}

export interface UpdateDraftPollInput_V2 {
  data: UpdateDraftPollDataInput_V2;
  isAnnouncement?: boolean | null;
  pollPostId: string;
}

export interface UpdateDynamicPermissionGroupInput {
  _id: string;
  data: UpdateDynamicPermissionGroupInputData;
}

export interface UpdateDynamicPermissionGroupInputData {
  description?: RawDraftContentState | null;
  name?: string | null;
  rule?: UserRuleInput | null;
}

export interface UpdateEventTicketInput {
  data: UpdateEventTicketInput_Data;
  ticketId: string;
}

export interface UpdateEventTicketInput_Data {
  attributeReviewConfigurations?: TicketAttributeReviewConfigurationInput[] | null;
  canPurchase?: UpdateEventTicketInput_Data_CanPurchase | null;
  description?: string | null;
  name?: string | null;
  price?: Money | null;
}

export interface UpdateEventTicketInput_Data_CanPurchase {
  magicTicketLink?: boolean | null;
  permissionConfig?: PermissionConfigInput | null;
}

export interface UpdateGroupCommunityVisibilityData {
  communityVisibility: ECommunityVisibility;
}

export interface UpdateGroupCommunityVisibilityInput {
  data: UpdateGroupCommunityVisibilityData;
  groupId: string;
}

export interface UpdateGroupDataInput {
  description?: string | null;
  groupBannerImageId?: string | null;
  groupProfileImageId?: string | null;
  libraryId?: string | null;
  name?: string | null;
  priority?: number | null;
  settings?: UpdateGroupSettingsDataInput | null;
}

export interface UpdateGroupInput {
  data: UpdateGroupDataInput;
  filter?: GroupFilterInput | null;
  id?: string | null;
}

export interface UpdateGroupLibraryFolderNameInput {
  libraryFolderId: string;
  name: string;
}

export interface UpdateGroupSettingsDataInput {
  communityVisibility?: ECommunityVisibility | null;
  defaultDigestPref?: EDigestPreference | null;
  findPermission?: EGroupFindPermission | null;
  selfJoinPermission?: EGroupSelfJoinPermission | null;
  viewContentPermission?: EGroupViewContentPermission | null;
}

export interface UpdateMembershipOrderInput {
  id: string;
  options: UpdateMembershipOrderInputOptions;
}

export interface UpdateMembershipOrderInputOptions {
  endDate?: string | null;
  isAutoPayEnabled?: boolean | null;
  isConsideredPaid?: boolean | null;
  membershipTypeId?: string | null;
  paymentInterval?: PaymentInterval | null;
  startDate?: string | null;
}

export interface UpdateMembershipTermRenewalSettingInput {
  membershipTermId: string;
  renewalSetting: MembershipTermRenewalSetting;
}

export interface UpdateMembershipTypeAttributeReviewConfigurationsInput {
  attributeReviewConfigurations?: MembershipTypeAttributeReviewConfigurationInput[] | null;
  id: string;
}

export interface UpdateMembershipTypeMembershipRenewalSettingsInput {
  data: UpdateMembershipTypeMembershipRenewalSettingsInput_Data;
  id: string;
}

export interface UpdateMembershipTypeMembershipRenewalSettingsInput_Data {
  gracePeriodInDays: number;
  renewalPeriodInDays: number;
}

export interface UpdateMembershipTypePaymentsInput {
  data: UpdateMembershipTypePaymentsInput_Data;
  id: string;
}

export interface UpdateMembershipTypePaymentsInput_Data {
  acceptedPaymentMethods: UpdateMembershipTypePaymentsInput_Data_AcceptedPaymentMethods;
  invoicing?: UpdateMembershipTypePaymentsInput_Invoicing | null;
  isInvoicingEnabled: boolean;
  monthlyPrice?: Money | null;
  price: Money;
}

export interface UpdateMembershipTypePaymentsInput_Data_AcceptedPaymentMethods {
  achTransfer?: boolean | null;
  creditCard?: boolean | null;
}

export interface UpdateMembershipTypePaymentsInput_Invoicing {
  method: EMembershipType_Invoicing_Method;
}

export interface UpdateModerationItemDataInput {
  reason?: string | null;
  resolution?: UpdateModerationItemResolutionDataInput | null;
  resolutionState?: string | null;
  tenantId?: string | null;
}

export interface UpdateModerationItemDataInputV2 {
  reason?: string | null;
  resolution?: UpdateModerationItemResolutionDataInput | null;
  resolutionState?: string | null;
}

export interface UpdateModerationItemInput {
  data: UpdateModerationItemDataInput;
  filter?: ModerationItemFilterInput | null;
  id?: string | null;
}

export interface UpdateModerationItemInputV2 {
  data: UpdateModerationItemDataInputV2;
  id: string;
}

export interface UpdateModerationItemResolutionDataInput {
  dateCreated?: string | null;
  note?: string | null;
  resolvedByUserId?: string | null;
}

export interface UpdateNavigationInput {
  items: NavigationItemInput[];
}

export interface UpdateOidcClientData {
  _id: string;
  redirectUris: string[];
}

export interface UpdateOidcClientInput {
  data: UpdateOidcClientData;
}

export interface UpdateOrganizationTierLogInput {
  data: UpdateOrganizationTierLogInput_Data;
  id: string;
}

export interface UpdateOrganizationTierLogInput_Data {
  disabled?: boolean | null;
  tierStartDate?: string | null;
  tierType?: ESponsorTierType | null;
}

export interface UpdateOrganizationV2Input {
  data: UpdateOrganizationV2Input_Data;
  id: string;
}

export interface UpdateOrganizationV2Input_Data {
  aboutUs?: RawDraftContentState | null;
  aboutUsAllowedViewers?: EAttributeAllowedViewerKind[] | null;
  ad?: JSON | null;
  city?: string | null;
  contactEmail?: string | null;
  country?: string | null;
  defaultDigestPref?: string | null;
  description?: string | null;
  descriptiveResources?: Organization_DescriptiveResource_DataInput[] | null;
  hidden?: boolean | null;
  hideFromMemberDirectory?: boolean | null;
  isMember?: boolean | null;
  isSponsor?: boolean | null;
  name?: string | null;
  phoneNumber?: string | null;
  requestDemoTitle?: string | null;
  sponsorBannerImageId?: string | null;
  sponsorProfilePictureId?: string | null;
  state?: string | null;
  streetAddress?: string | null;
  updateWithAdmins?: UpdateOrganizationV2Input_Data_UpdateWithAdmins | null;
  website?: string | null;
  zipCode?: string | null;
}

export interface UpdateOrganizationV2Input_Data_UpdateWithAdmins {
  adminUserIds: string[];
  newAdminUserData: UpdateOrganizationV2Input_Data_UpdateWithAdmins_NewAdminUserData[];
}

export interface UpdateOrganizationV2Input_Data_UpdateWithAdmins_NewAdminUserData {
  email: string;
  firstName: string;
  lastName: string;
  sendEmailInvitation: boolean;
}

export interface UpdatePageInput {
  content: PageContentInput[];
  pageId: string;
  pageVersion: number;
}

export interface UpdatePageSettingsInput {
  isDeleted?: boolean | null;
  isMarketingHomepage?: boolean | null;
  name?: string | null;
  pageId: string;
  pageVersion: number;
  restriction?: EPageRestriction | null;
  slug?: string | null;
  titleTag?: string | null;
}

export interface UpdatePaymentMethodData {
  setToNewDefault?: boolean | null;
}

export interface UpdatePaymentMethodInput {
  data: UpdatePaymentMethodData;
  paymentMethodId: string;
}

export interface UpdatePermissionGroupInput {
  _id: string;
  data: UpdatePermissionGroupInputData;
}

export interface UpdatePermissionGroupInputData {
  description?: RawDraftContentState | null;
  name?: string | null;
}

export interface UpdatePostDataInput_V2 {
  body?: JSON | null;
  embeddedImageIds?: (string | null)[] | null;
  groupId?: string | null;
  pinned?: boolean | null;
  tagIds?: (string | null)[] | null;
  title?: string | null;
}

export interface UpdatePostInput_V2 {
  data: UpdatePostDataInput_V2;
  filter?: PostFilterInput | null;
  id?: string | null;
}

export interface UpdateQuestionDataInput_V2 {
  body?: RawDraftContentState | null;
  embeddedImageIds?: string[] | null;
  groupId?: string | null;
  scheduledPublishDate?: string | null;
  tagIds?: string[] | null;
  title?: string | null;
  versionFileIds?: string[] | null;
}

export interface UpdateQuestionInput_V2 {
  data: UpdateQuestionDataInput_V2;
  isAnnouncement?: boolean | null;
  questionPostId: string;
}

export interface UpdateRsvpResponseInput {
  id: string;
  response: ERsvpResponse;
}

export interface UpdateSimpleEventInput {
  _id: string;
  data: UpdateSimpleEventInput_Data;
}

export interface UpdateSimpleEventInput_Data {
  alternativeVideoFileId?: string | null;
  bannerImageId?: string | null;
  canonicalVideoFileId?: string | null;
  description?: RawDraftContentState | null;
  emailPreferences?: EventEmailPreferencesInput | null;
  endDate?: string | null;
  externalRegistrationUrl?: string | null;
  isExternalRegistrationUrlActive?: boolean | null;
  linkToRecording?: LinkToRecordingInput | null;
  name?: string | null;
  speakersV2?: NewOrExistingSpeakerInput[] | null;
  sponsorData?: KeyAndAdditionalSponsorInputs | null;
  startDate?: string | null;
  timeZone?: string | null;
  venue?: EventVenueInput | null;
  visibility?: EventVisibilityInput | null;
}

export interface UpdateSimplePostInput {
  data: UpdateSimplePostInput_Data;
  isAnnouncement?: boolean | null;
  simplePostId: string;
}

export interface UpdateSimplePostInput_Data {
  body?: RawDraftContentState | null;
  embeddedImageIds?: string[] | null;
  groupId?: string | null;
  scheduledPublishDate?: string | null;
  tagIds?: string[] | null;
  title?: string | null;
  versionFileIds?: string[] | null;
}

export interface UpdateTagInput {
  _id: string;
  name: string;
}

export interface UpdateTenantDataInput {
  accountCreationPermission?: EAccountCreationPermission | null;
  acronym?: string | null;
  codeOfConduct?: RawDraftContentState | null;
  communityAccessConfig?: ECommunityAccessConfig | null;
  createdAt?: string | null;
  customContactSupportLinkV2?: UpdateCustomLinkInput | null;
  customEditAMSProfileLink?: UpdateCustomLinkInput | null;
  customExploreMembershipsLinkV2?: UpdateCustomLinkInput | null;
  defaultUserInvitationEmailContent?: UpdateTenantDefaultUserInvitationEmailContentDataInput | null;
  defaultUserWelcomeEmailContent?: UpdateTenantDefaultUserWelcomeEmailContentDataInput | null;
  description?: string | null;
  displayName?: string | null;
  eventsFeedConfig?: EEventsFeedAccessConfig | null;
  featureFlags?: UpdateTenantFeatureFlagsDataInput | null;
  globalSingletonGroupEntityName?: string | null;
  logoIconId?: JSON | null;
  logoIconImageId?: string | null;
  logoId?: JSON | null;
  logoImageId?: string | null;
  logoLargeId?: JSON | null;
  logoLargeImageId?: string | null;
  memberDirectoryConfig?: EMemberDirectoryAccessConfig | null;
  memberEntityName?: string | null;
  obnoxiousCodeOfConduct?: boolean | null;
  organizationDirectoryEnabled?: boolean | null;
  organizationEntityName?: string | null;
  paymentAccountId?: string | null;
  primaryColor?: string | null;
  privacyPolicy?: RawDraftContentState | null;
  privacyPolicyLink?: UpdateCustomLinkInput | null;
  restrictSubdomainForCompanyAccountOwners?: boolean | null;
  sponsorDirectoryAccessConfig?: ESponsorDirectoryAccessConfig | null;
  stripeCustomerId?: string | null;
  termsAndConditions?: RawDraftContentState | null;
  useEmailLoginLinks?: boolean | null;
  useReplyByEmail?: boolean | null;
}

export interface UpdateTenantDefaultUserInvitationEmailContentDataInput {
  body?: string | null;
  subject?: string | null;
}

export interface UpdateTenantDefaultUserWelcomeEmailContentDataInput {
  body: string;
  subject: string;
}

export interface UpdateTenantDemoConfigData {
  isDemo: boolean;
}

export interface UpdateTenantDemoConfigInput {
  data: UpdateTenantDemoConfigData;
}

export interface UpdateTenantFeatureFlagsDataInput {
  cms?: boolean | null;
  conferences?: boolean | null;
  memberManagement?: boolean | null;
  memberManagementAdminOnly?: boolean | null;
}

export interface UpdateTenantInput {
  data: UpdateTenantDataInput;
  id: string;
}

export interface UpdateTenantStripeCustomerIdInput {
  stripeCustomerId?: string | null;
}

export interface UpdateTenantStripeCustomerInput {
  data: TenantStripeCustomerInput;
  stripeCustomerId: string;
}

export interface UpdateUserAccountDataInput {
  data: UpdateUserAccountDataInput_Data;
  userId: string;
}

export interface UpdateUserAccountDataInput_Data {
  email?: string | null;
  integrationId?: string | null;
  integrationIsMember?: boolean | null;
  roleData?: UpdateUserAccountDataInput_Data_RoleData | null;
}

export interface UpdateUserAccountDataInput_Data_RoleData {
  newOrganizationData?: UpdateUserAccountDataInput_Data_RoleData_NewOrganizationData | null;
  organizationId?: string | null;
  userRole: EUserRole;
}

export interface UpdateUserAccountDataInput_Data_RoleData_NewOrganizationData {
  isSponsor: boolean;
  name: string;
}

export interface UpdateUserConsentInput {
  consented: boolean;
  consentType: UserConsentType;
  orgId: string;
  userId: string;
}

export interface UpdateUserDataInput {
  addressAdministrativeArea?: string | null;
  addressAdministrativeAreaPrivacySetting?: string | null;
  addressLine0?: string | null;
  addressLine0PrivacySetting?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  addressLocality?: string | null;
  addressLocalityPrivacySetting?: string | null;
  addressPostalCode?: string | null;
  addressPostalCodePrivacySetting?: string | null;
  addressRegionCode?: string | null;
  addressRegionCodePrivacySetting?: string | null;
  addressSublocality?: string | null;
  atMentionNotification?: EAtMentionNotificationSetting | null;
  bioPrivacySetting?: string | null;
  bioRichText?: RawDraftContentState | null;
  bioRichTextAllowedViewers?: EAttributeAllowedViewerKind[] | null;
  bioRichTextPrivacySetting?: string | null;
  company?: string | null;
  companyPrivacySetting?: string | null;
  contactEmail?: string | null;
  deactivated?: boolean | null;
  emailPrivacySetting?: string | null;
  emails?: (JSON | null)[] | null;
  eventConfirmationEmails?: boolean | null;
  eventReminderEmails?: boolean | null;
  eventSummaryEmails?: boolean | null;
  firstName?: string | null;
  hideFromMemberDirectory?: boolean | null;
  integrationIsMember?: boolean | null;
  jobTitle?: string | null;
  jobTitlePrivacySetting?: string | null;
  lastName?: string | null;
  memberSince?: string | null;
  moderatedByDefault?: boolean | null;
  nameTitle?: string | null;
  nameTitlePrivacySetting?: string | null;
  onBoardingTasksVersionCompleted?: number | null;
  optOutAllCommunityEmails?: boolean | null;
  primaryPhone?: string | null;
  primaryPhoneNormalized?: string | null;
  primaryPhonePrivacySetting?: string | null;
  privateCommunityProfile?: boolean | null;
  privateMessageNotification?: boolean | null;
  sponsoredEventEmails?: boolean | null;
  stripeCustomerId?: string | null;
  tenantId?: string | null;
  tooltipJourneyState?: string | null;
  userProfilePictureId?: string | null;
}

export interface UpdateUserGroupLinkDataInput {
  digestPreference?: string | null;
  externalId?: string | null;
  lastAnnouncementViewDate?: string | null;
  lastIntegrationJobId?: string | null;
  tenantId?: string | null;
  userGroupRole?: string | null;
}

export interface UpdateUserGroupLinkInput {
  data: UpdateUserGroupLinkDataInput;
  filter?: UserGroupLinkFilterInput | null;
  id?: string | null;
}

export interface UpdateUserInput {
  data: UpdateUserDataInput;
  filter?: UserFilterInput | null;
  id?: string | null;
}

export interface UpdateUserNoteInput {
  id: string;
  patch: UpdateUserNoteInput_Patch;
}

export interface UpdateUserNoteInput_Patch {
  body?: string | null;
}

export interface UpdateUserOrganizationLinkDataInput {
  digestPreference?: string | null;
}

export interface UpdateUserOrganizationLinkInput {
  data?: UpdateUserOrganizationLinkDataInput | null;
  id?: string | null;
}

export interface UpdateVersionFileNameInput {
  name: string;
  versionFileId: string;
}

export interface UpsertPollResponseForCurrentUserData {
  optionId: string;
}

export interface UpsertPollResponseForCurrentUserInput {
  data?: UpsertPollResponseForCurrentUserData | null;
}

export interface UserCanAccessCommunityFilterInput {
  active?: boolean | null;
}

export interface UserConsentsInput {
  consentType: UserConsentType;
  limit?: number | null;
  offset?: number | null;
  searchKey?: string | null;
  userId: string;
}

export interface UserDeactivatedFilterFilterInput {
  active?: boolean | null;
}

export interface UserFacetInput {
  acceptedCodeOfConduct?: BooleanFacetInput | null;
  accountStatus?: EnumFacetInput | null;
  company?: StringFacetInput | null;
  email?: StringFacetInput | null;
  jobTitle?: StringFacetInput | null;
  memberType?: StringFacetInput | null;
  moderatedByDefault?: BooleanFacetInput | null;
  username?: StringFacetInput | null;
}

export interface UserFilterInput {
  _and?: (UserFilterInput | null)[] | null;
  _canAccessCommunity?: UserCanAccessCommunityFilterInput | null;
  _deactivatedFilter?: UserDeactivatedFilterFilterInput | null;
  _hasRegularRole?: HasRegularRoleFilterInput | null;
  _hiddenUsers?: UserHiddenUsersFilterInput | null;
  _id?: String_Selector | null;
  _isMember?: UserIsMemberFilterInput | null;
  _not?: UserFilterInput | null;
  _or?: (UserFilterInput | null)[] | null;
  _withTextSearch?: UserWithTextSearchFilterInput | null;
  acceptedCodeOfConduct?: Date_Selector | null;
  accountStatus?: String_Selector | null;
  addressAdministrativeAreaPrivacySetting?: String_Selector | null;
  addressLine0PrivacySetting?: String_Selector | null;
  addressLocalityPrivacySetting?: String_Selector | null;
  addressPostalCodePrivacySetting?: String_Selector | null;
  addressRegionCodePrivacySetting?: String_Selector | null;
  bioPrivacySetting?: String_Selector | null;
  bioRichTextPrivacySetting?: String_Selector | null;
  canAccessCommunity?: Boolean_Selector | null;
  closedAppDownloadBannerDesktop?: Date_Selector | null;
  closedAppDownloadBannerMobile?: Date_Selector | null;
  companyPrivacySetting?: String_Selector | null;
  contactEmail?: String_Selector | null;
  deactivated?: Boolean_Selector | null;
  displayName?: String_Selector | null;
  emailPrivacySetting?: String_Selector | null;
  eventConfirmationEmails?: Boolean_Selector | null;
  eventReminderEmails?: Boolean_Selector | null;
  eventSummaryEmails?: Boolean_Selector | null;
  fullName?: String_Selector | null;
  integrationIsMember?: Boolean_Selector | null;
  jobTitlePrivacySetting?: String_Selector | null;
  memberSince?: Date_Selector | null;
  memberType?: String_Selector | null;
  middleName?: String_Selector | null;
  moderatedByDefault?: Boolean_Selector | null;
  nameSuffix?: String_Selector | null;
  nameTitlePrivacySetting?: String_Selector | null;
  onBoardingTasksVersionCompleted?: Float_Selector | null;
  primaryPhoneNormalized?: String_Selector | null;
  primaryPhonePrivacySetting?: String_Selector | null;
  privateMessageNotification?: Boolean_Selector | null;
  profilePictureId?: String_Selector | null;
  proxyProfilePictureUrl?: String_Selector | null;
  sponsoredEventEmails?: Boolean_Selector | null;
  stripeCustomerId?: String_Selector | null;
  tagsFollowedCount?: Int_Selector | null;
  tenantId?: String_Selector | null;
  tooltipJourneyState?: String_Selector | null;
}

export interface UserGroupLinkFilterInput {
  _and?: (UserGroupLinkFilterInput | null)[] | null;
  _id?: String_Selector | null;
  _not?: UserGroupLinkFilterInput | null;
  _or?: (UserGroupLinkFilterInput | null)[] | null;
  dateCreated?: Date_Selector | null;
  digestPreference?: String_Selector | null;
  groupId?: String_Selector | null;
  lastAnnouncementViewDate?: Date_Selector | null;
  tenantId?: String_Selector | null;
  userGroupRole?: String_Selector | null;
  userId?: String_Selector | null;
}

export interface UserHiddenUsersFilterInput {
  active?: boolean | null;
}

export interface UserHistoryConnectionFilterInput {
  includeMembershipHistory?: boolean | null;
  includeNotesHistory?: boolean | null;
  includeTransactionHistory?: boolean | null;
}

export interface UserHistoryConnectionInput {
  after?: string | null;
  filters?: UserHistoryConnectionFilterInput | null;
  first: number;
}

export interface UserIdentifierInput {
  _id?: string | null;
  email?: string | null;
  integrationId?: string | null;
}

export interface UserIntegrationIdExistsInput {
  integrationId: string;
  userId?: string | null;
}

export interface UserIsMemberFilterInput {
  active?: boolean | null;
}

export interface UserOrganizationLinkFilterInput {
  userId?: String_Selector | null;
}

export interface UserRuleInput {
  facets: UserFacetInput[];
  matchCondition: MatchCondition;
}

export interface UserSearchInput {
  filter?: UserSearchInput_Filter | null;
  limit: number;
  offset: number;
}

export interface UserSearchInput_Filter {
  accountStatusV2?: UserSearchInput_Filter_EAccountStatus[] | null;
  membershipStatusV3?: MembershipStatus[] | null;
  membershipTypeNames?: string[] | null;
  role?: EUserRole[] | null;
  searchText?: string | null;
  termAlerts?: MembershipTermAlertSearchFilter[] | null;
}

export interface UserSelectorUniqueInput {
  _id?: string | null;
  documentId?: string | null;
  slug?: string | null;
}

export interface UserSortInput {
  _id?: SortOptions | null;
  acceptedCodeOfConduct?: SortOptions | null;
  accountStatus?: SortOptions | null;
  addressAdministrativeAreaPrivacySetting?: SortOptions | null;
  addressLine0PrivacySetting?: SortOptions | null;
  addressLocalityPrivacySetting?: SortOptions | null;
  addressPostalCodePrivacySetting?: SortOptions | null;
  addressRegionCodePrivacySetting?: SortOptions | null;
  badgeLinks?: SortOptions | null;
  bioPrivacySetting?: SortOptions | null;
  bioRichTextPrivacySetting?: SortOptions | null;
  canAccessCommunity?: SortOptions | null;
  closedAppDownloadBannerDesktop?: SortOptions | null;
  closedAppDownloadBannerMobile?: SortOptions | null;
  companyPrivacySetting?: SortOptions | null;
  contactEmail?: SortOptions | null;
  deactivated?: SortOptions | null;
  displayName?: SortOptions | null;
  emailPrivacySetting?: SortOptions | null;
  eventConfirmationEmails?: SortOptions | null;
  eventReminderEmails?: SortOptions | null;
  eventSummaryEmails?: SortOptions | null;
  fullName?: SortOptions | null;
  globalPermissions?: SortOptions | null;
  groupLinks?: SortOptions | null;
  groupPermissions?: SortOptions | null;
  integrationIsMember?: SortOptions | null;
  jobTitlePrivacySetting?: SortOptions | null;
  memberSince?: SortOptions | null;
  memberType?: SortOptions | null;
  middleName?: SortOptions | null;
  moderatedByDefault?: SortOptions | null;
  nameSuffix?: SortOptions | null;
  nameTitlePrivacySetting?: SortOptions | null;
  onBoardingTasksVersionCompleted?: SortOptions | null;
  permissionGroups?: SortOptions | null;
  primaryPhoneNormalized?: SortOptions | null;
  primaryPhonePrivacySetting?: SortOptions | null;
  privateMessageNotification?: SortOptions | null;
  profilePictureId?: SortOptions | null;
  proxyProfilePictureUrl?: SortOptions | null;
  services?: SortOptions | null;
  sponsoredEventEmails?: SortOptions | null;
  stripeCustomerId?: SortOptions | null;
  tagLinks?: SortOptions | null;
  tagsFollowedCount?: SortOptions | null;
  tenantId?: SortOptions | null;
  tooltipJourneyState?: SortOptions | null;
}

export interface UserWithIntegrationRecordFromUserIdentifierInput {
  userIdentifier: UserIdentifierInput;
}

export interface UserWithTextSearchFilterInput {
  restrictToUserIds?: (string | null)[] | null;
  searchText: string;
}

export interface UsersByIdsInput {
  userIds: string[];
}

export interface ValidateYourMembershipSessionInput {
  session: string;
}

export interface requestSSOToken_data {
  redirect_uri?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
