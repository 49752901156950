import { gql, useMutation } from '@apollo/client';
import { Box, Button, Flex, FormControl, Link, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

import { logError } from '@/imports/logging/ClientLogger';

import StepProgress from '../../../StepProgress';
import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';

export interface INewUserConsentConfirmationForInformationSharingProps {
  isLastStep: boolean;
  followedSponsorIds: string[];
  onBack: () => void;
}
const FOLLOW_SPONSORS = gql`
  mutation NewUserFollowSponsors_FollowSponsors($input: FollowSponsorsInput!) {
    followSponsors(input: $input) {
      user {
        _id
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
      sponsors {
        _id
        userOrganizationLink {
          _id
        }
      }
    }
  }
`;
export const NewUserConsentConfirmationForInformationSharing: React.FC<
  INewUserConsentConfirmationForInformationSharingProps
> = (props) => {
  const [followSponsors, followSponsorsResult] = useMutation(FOLLOW_SPONSORS);
  const toast = useToast();

  const saveSponsors = async (sponsorIds: string[]): Promise<void> => {
    try {
      await followSponsors({
        variables: {
          input: {
            data: {
              sponsorIds: sponsorIds,
            },
          },
        },
      });
    } catch (err) {
      toast({
        title: 'Follow Sponsors failed',
        status: 'error',
      });
      logError(err);
    }
  };
  const onAgree = () => {
    saveSponsors(props.followedSponsorIds);
  };

  const onDecline = () => {
    saveSponsors([]);
  };

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
    >
      <ActivationModalHeader title="We Value Your Privacy" subtitle="" />
      <ActivationModalBody>
        <FormControl marginTop={'10px'}>
          <Flex gap={4} align={'end'} direction={'column'}>
            <Text>
              By clicking “I Agree”, you consent to sharing your contact details
              (name, email, phone number) with the specified sponsor(s). They
              may use this information to provide you with updates, offers, and
              service-related communication. Your data will only be shared with
              these sponsor(s) you follow.
            </Text>
          </Flex>
        </FormControl>
        <Flex align="end">
          <Link ml="auto" color="primary.500" onClick={props.onBack}>
            Go Back
          </Link>
        </Flex>
      </ActivationModalBody>
      <ActivationModalFooter>
        <Button variant={'outline'} onClick={onDecline}>
          Decline
        </Button>
        <StepProgress />
        <Button onClick={onAgree}>I Agree</Button>
      </ActivationModalFooter>
    </Box>
  );
};
